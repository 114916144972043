import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { User } from '../../classes/user';
import { Router } from '@angular/router';
import { AuthService } from '@modules/auth/services/auth.service';
import { AuthUserService } from '@modules/auth/services/auth-user.service';
import { Institution } from '@shared/classes/institution';
import { SharedInstitutionDataStore } from '@shared/services/shared-institution-data-store.service';
import { SidenavService } from '@shared/services/sidenav.service';
import { take, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { InstitutionService } from '@modules/institution/services/institution.service';

@Component({
    selector: 'shared-left-menu-presentation',
    templateUrl: './shared-left-menu-presentation.component.html',
    styleUrls: ['./shared-left-menu-presentation.component.scss']
})
export class SharedLeftMenuPresentationComponent implements OnInit, OnDestroy {
    private _user: User;
    @Input() selectedInstitution: Institution; // This must come before the user setter.
    // Otherwise there is no selectedInstitution when this._checkUserRole is called.
    @Input() set user(user: User) {
        this._user = user;
        this.isUserAdminOrSuperAdmin = this._checkUserRole();
    }

    get user(): User {
        return this._user;
    }
    @Output() hide = new EventEmitter<any>();
    currentYear: number = new Date().getFullYear();
    hasAccessToSingleInstitution: boolean;
    isUserAdminOrSuperAdmin: boolean = false;

    private _destroySubject: Subject<void> = new Subject<void>();
    private _destroy$: Observable<void> = this._destroySubject.asObservable();

    constructor(
        private router: Router,
        private _authUserService: AuthUserService,
        private _sharedInstitutionDataStore: SharedInstitutionDataStore,
        private _sidenavService: SidenavService,
        private _authService: AuthService,
        private _institutionService: InstitutionService
    ) {}

    ngOnInit() {
        this.hasAccessToSingleInstitution = this._institutionService.userOnlyHasOneInstitution;
    }

    ngOnDestroy(): void {
        this._destroySubject.next();
        this._destroySubject.complete();
    }

    onLogout() {
        this._authService.logout();
    }

    selectedInstitutionName(): string {
        return this.selectedInstitution ? this.selectedInstitution.name : null;
    }

    selectedInstitutionCity(): string {
        return this.selectedInstitution.address
            ? this.selectedInstitution.address.city
            : null;
    }

    selectedInstitutionState(): string {
        return this.selectedInstitution.address
            ? this.selectedInstitution.address.state.name
                ? this.selectedInstitution.address.state.name
                : null
            : null;
    }

    hasLocationInfo(): boolean {
        return (
            !!this.selectedInstitution.address &&
            this.selectedInstitution.address.city &&
            this.selectedInstitution.address.state &&
            this.selectedInstitution.address.city !== '' &&
            this.selectedInstitution.address.state.name !== ''
        );
    }

    handleFocus = (event: FocusEvent) => {
        this._sidenavService.showAside$
            .pipe(
                take(1),
                takeUntil(this._destroy$)
            )
            .subscribe((flag: boolean) => {
                if (!flag) {
                    this._sidenavService.focusExpandSidenavButton();
                }
            });
    };

    private _checkUserRole() {
        let result: boolean = false;
        if (
            this.selectedInstitution &&
            this.selectedInstitution.userRole === 'ROLE_ADMIN'
        ) {
            result = true;
        } else if (this.user && this.user.superUser) {
            result = true;
        }
        return result;
    }
}
