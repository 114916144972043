import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { User } from '@shared/classes/user';
import { Credentials } from '@shared/classes/credentials';
import { SharedConstantsService } from '@shared/services/shared-constants.service';

import { map, catchError, switchMap } from 'rxjs/operators';
import { AuthUserService } from './auth-user.service';
import { Router } from '@angular/router';
import { SharedBaseService } from '@shared/services/shared-base.service';
import { SharedMyMessageService } from '@shared/services/shared-my-message.service';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends SharedBaseService {
    private readonly tokenKey = 'userToken';

    private _resetPasswordTokenBehaviorSubject: BehaviorSubject<
        string
    > = new BehaviorSubject<string>(undefined);
    resetPasswordToken$: Observable<
        string
    > = this._resetPasswordTokenBehaviorSubject.asObservable();

    private _clearUserDataSubject: Subject<void> = new Subject<void>();
    clearUserData$: Observable<
        void
    > = this._clearUserDataSubject.asObservable();

    constructor(
        private _Constants: SharedConstantsService,
        private _authUserService: AuthUserService,
        http: HttpClient,
        private __sharedMyMessageService: SharedMyMessageService,
        private _router: Router
    ) {
        super(http, __sharedMyMessageService);
    }

    isAuthorized = (): boolean => {
        const token: string = this.getToken();
        return !!token;
    };

    private saveToken = (aToken: string) => {
        localStorage.setItem(this.tokenKey, aToken);
    };

    private clearToken = () => {
        localStorage.removeItem(this.tokenKey);
    };

    getToken = (): string => {
        const token = localStorage.getItem(this.tokenKey);
        return token;
    };

    hasAcceptedTC = (): boolean => {
        const userStr: string = localStorage.getItem('user');
        const user: User = new User(JSON.parse(userStr));
        return user.currentTermsAndConditions;
    };

    tcPath = (): string => {
        const userStr: string = localStorage.getItem('user');
        const user: User = new User(JSON.parse(userStr));
        return user.currentTermsAndConditionsPath;
    };

    acceptTerms(): Observable<any> {
        const token = localStorage.getItem('userToken');
        return this.http.post<any>(
            `${this.baseUrl}${SharedConstantsService.ENDPOINTS.TERMS_AND_CONDITIONS}`,
            {},
            {
                headers: {
                    Authorization: token
                }
            }
        );
    }

    /**
     * Will attempt login for user's given credentials and return a user object with associated token
     * Token and user object will both be saved to local storage
     * @param {Credentials} credentials
     * @returns {Observable<{token: string; user: User}>}
     */
    login(credentials: Credentials): Observable<{ token: string; user: User }> {
        return this.http
            .post<any>(
                `${this.baseUrl}${SharedConstantsService.ENDPOINTS.LOGIN}`,
                credentials
            )
            .pipe(
                map(res => {
                    const associationIds: number[] = [];
                    for (let i = 0; i < res.user.associations.length; i++) {
                        associationIds.push(res.user.associations[i].id);
                    }
                    const loggedInUser = new User({
                        id: res.user.id,
                        email: res.user.email,
                        firstName: res.user.firstName,
                        middleName: null,
                        lastName: res.user.lastName,
                        token: res.token,
                        superUser: res.user.superUser,
                        associations: res.user.associations,
                        associationIds: associationIds,
                        currentTermsAndConditions:
                            res.user.currentTermsAndConditions,
                        currentTermsAndConditionsPath:
                            res.user.currentTermsAndConditionsPath,
                        ssoUserId: res.user.ssoUserId
                    });
                    this.saveToken(loggedInUser.token);
                    this._authUserService.setUser(loggedInUser);
                    return {
                        token: loggedInUser.token,
                        user: loggedInUser
                    };
                })
            );
    }

    loginByToken = (
        token: string,
        customerId: string
    ): Observable<{ token: string; user: User }> => {
        return this.http
            .get(`${this.baseUrl}/loginByToken/${token}/${customerId}`)
            .pipe(
                switchMap(
                    (res: {
                        token: string;
                        user: User;
                    }): Observable<{
                        token: string;
                        user: User;
                    }> => {
                        const associationIds: number[] = [];
                        if (!res.user.associations) {
                            // logout in case of no user associations. api is not throwing 401 error for some reason in case of expired token
                            this.logoutOnClientOnly();
                        }
                        for (let i = 0; i < res.user.associations.length; i++) {
                            associationIds.push(res.user.associations[i].id);
                        }
                        const loggedInUser = new User({
                            id: res.user.id,
                            email: res.user.email,
                            firstName: res.user.firstName,
                            middleName: null,
                            lastName: res.user.lastName,
                            token: res.token,
                            superUser: res.user.superUser,
                            associations: res.user.associations,
                            associationIds: associationIds,
                            currentTermsAndConditions:
                                res.user.currentTermsAndConditions,
                            currentTermsAndConditionsPath:
                                res.user.currentTermsAndConditionsPath,
                            ssoUserId: res.user.ssoUserId
                        });
                        this.saveToken(loggedInUser.token);
                        this._authUserService.setUser(loggedInUser);
                        return of({
                            token: loggedInUser.token,
                            user: loggedInUser
                        });
                    }
                ),
                catchError(this.handleError)
            );
    };

    validateForgotPasswordLink(link: string): Observable<any> {
        const payload = { url: link };
        return this.http.post<any>(
            `${this.baseUrl}${SharedConstantsService.ENDPOINTS.FORGOT_PASSWORD_VALIDATE}`,
            payload
        );
    }

    logout(): void {
        this.logoutOnClientOnly();
    }

    clearUserSession(): void {
        this.clearToken();
        this._authUserService.clearUser();
        this._clearUserDataSubject.next();
    }

    logoutOnClientOnly(): void {
        this.clearToken();
        this._authUserService.clearUser();
        this._clearUserDataSubject.next();
        window.open(environment.myJourneyUrl + 'logout', '_self');
    }

    forgotPassword(email: String): Observable<any> {
        return this.http
            .post<any>(
                `${this.baseUrl}${SharedConstantsService.ENDPOINTS.FORGOT_PASSWORD}`,
                { email: email }
            )
            .pipe(
                map(res => {
                    return res;
                })
            );
    }

    saveResetToken = (rToken: string) => {
        this._resetPasswordTokenBehaviorSubject.next(rToken);
    };

    resetPassword(newPass: string, resetUrl: string): Observable<any> {
        const payload = { url: resetUrl, password: newPass };
        return this.http.put<any>(
            `${this.baseUrl}${SharedConstantsService.ENDPOINTS.RESET_PASSWORD}`,
            payload,
            { responseType: 'text' as 'json' }
        );
    }

    validatePassword(newPass: string): Observable<any> {
        const payload = {
            password: newPass
        };
        return this.http.post<any>(
            `${this.baseUrl}${SharedConstantsService.ENDPOINTS.VALIDATE_PASSWORD}`,
            payload
        );
    }
}
