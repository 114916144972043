import * as tslib_1 from "tslib";
import { OnDestroy, InjectionToken } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
export var OVERLAY_PARENT_HTML = new InjectionToken('OVERLAY_PARENT_HTML');
var SharedInMainContainerOverlayContainer = /** @class */ (function (_super) {
    tslib_1.__extends(SharedInMainContainerOverlayContainer, _super);
    function SharedInMainContainerOverlayContainer(_document) {
        return _super.call(this, _document) || this;
    }
    SharedInMainContainerOverlayContainer.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    SharedInMainContainerOverlayContainer.prototype.getRootElement = function () {
        return this._document.querySelector('.main-container');
    };
    SharedInMainContainerOverlayContainer.prototype._createContainer = function () {
        _super.prototype._createContainer.call(this);
        this._appendToRootComponent();
    };
    SharedInMainContainerOverlayContainer.prototype._appendToRootComponent = function () {
        if (!this._containerElement) {
            return;
        }
        var rootElement = this.getRootElement();
        var parent = rootElement || this._document.body;
        parent.appendChild(this._containerElement);
    };
    SharedInMainContainerOverlayContainer.ngInjectableDef = i0.defineInjectable({ factory: function SharedInMainContainerOverlayContainer_Factory() { return new SharedInMainContainerOverlayContainer(i0.inject(i1.DOCUMENT)); }, token: SharedInMainContainerOverlayContainer, providedIn: "root" });
    return SharedInMainContainerOverlayContainer;
}(OverlayContainer));
export { SharedInMainContainerOverlayContainer };
