import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var SharedCdkPopoverMessageService = /** @class */ (function () {
    function SharedCdkPopoverMessageService() {
        var _this = this;
        this.OPEN = 'OPEN';
        this.CLOSED = 'CLOSED';
        this._popoverOpenClosedEventBehaviorSubject = new BehaviorSubject(this.CLOSED);
        this.popoverOpenClosedEvent$ = this._popoverOpenClosedEventBehaviorSubject.asObservable();
        this.open = function () {
            _this._popoverOpenClosedEventBehaviorSubject.next(_this.OPEN);
        };
        this.close = function () {
            _this._popoverOpenClosedEventBehaviorSubject.next(_this.CLOSED);
        };
    }
    SharedCdkPopoverMessageService.ngInjectableDef = i0.defineInjectable({ factory: function SharedCdkPopoverMessageService_Factory() { return new SharedCdkPopoverMessageService(); }, token: SharedCdkPopoverMessageService, providedIn: "root" });
    return SharedCdkPopoverMessageService;
}());
export { SharedCdkPopoverMessageService };
