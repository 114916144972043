import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharedCdkPopoverMessageService {
    readonly OPEN: 'OPEN' = 'OPEN';
    readonly CLOSED: 'CLOSED' = 'CLOSED';
    private _popoverOpenClosedEventBehaviorSubject: BehaviorSubject<
        'OPEN' | 'CLOSED'
    > = new BehaviorSubject<'OPEN' | 'CLOSED'>(this.CLOSED);
    popoverOpenClosedEvent$: Observable<
        'OPEN' | 'CLOSED'
    > = this._popoverOpenClosedEventBehaviorSubject.asObservable();
    constructor() {}

    open = () => {
        this._popoverOpenClosedEventBehaviorSubject.next(this.OPEN);
    };

    close = () => {
        this._popoverOpenClosedEventBehaviorSubject.next(this.CLOSED);
    };
}
