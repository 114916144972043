/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "primeng/components/common/messageservice";
import * as i3 from "./app.component";
import * as i4 from "./modules/auth/services/auth.service";
import * as i5 from "./modules/auth/services/auth-user.service";
import * as i6 from "./shared/services/shared-my-message.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵprd(512, null, i2.MessageService, i2.MessageService, []), i0.ɵdid(2, 245760, null, 0, i3.AppComponent, [i1.Router, i4.AuthService, i5.AuthUserService, i2.MessageService, i6.SharedMyMessageService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i3.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
