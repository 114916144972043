import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var AuthUserService = /** @class */ (function () {
    function AuthUserService() {
        var _this = this;
        this.userKey = 'user';
        this._userSubject = new BehaviorSubject(undefined);
        this.user$ = this._userSubject.asObservable();
        this.clearUser = function () {
            localStorage.removeItem(_this.userKey);
            _this._userSubject.next(undefined);
        };
        this.user$.subscribe(function (user) {
            _this._user = user;
        });
    }
    AuthUserService.prototype.setUser = function (user) {
        this._userSubject.next(user);
        this.saveUser(user);
    };
    AuthUserService.prototype.saveUser = function (user) {
        try {
            localStorage.setItem(this.userKey, JSON.stringify(user));
        }
        catch (err) {
            console.error("Caught error setting item: " + err.message);
        }
    };
    AuthUserService.prototype.getDirectAssociations = function () {
        return this._user ? this._user.associations : null;
    };
    AuthUserService.prototype.loadUser = function () {
        try {
            var savedUser = JSON.parse(localStorage.getItem(this.userKey));
            this._userSubject.next(savedUser);
            return savedUser;
        }
        catch (err) {
            console.error("Caught error setting item: " + err.message);
            return undefined;
        }
    };
    AuthUserService.ngInjectableDef = i0.defineInjectable({ factory: function AuthUserService_Factory() { return new AuthUserService(); }, token: AuthUserService, providedIn: "root" });
    return AuthUserService;
}());
export { AuthUserService };
