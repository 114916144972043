import {
    ChangeDetectorRef,
    Input,
    QueryList,
    Renderer2,
    ViewChildren
} from '@angular/core';
import { SharedMyButtonDirective } from '@shared/directives/shared-my-button.directive';
import { MyMenuItem } from '@shared/classes/MyMenuItem.interface';
import { MenuItemTypeEnum } from '@app/shared/classes/menu-item-type-enum';

export abstract class SharedBaseMenuComponent {
    @Input() menuOptions: MyMenuItem[] = [];
    @ViewChildren(SharedMyButtonDirective) myButtons: QueryList<
        SharedMyButtonDirective
    >;
    protected constructor(
        protected _renderer2: Renderer2,
        protected _changeDetectorRef: ChangeDetectorRef
    ) {}

    abstract toggleShowMenu = (
        event: KeyboardEvent | MouseEvent,
        args?: any
    ): void => {};

    focusOptionIndex = (index: number) => {
        const buttons = this.myButtons.toArray();
        if (buttons && buttons[index]) {
            buttons[index].elementRef.nativeElement.focus();
        }
    };

    resetMenuFocus = (
        event: KeyboardEvent,
        index: number,
        shouldToggle: boolean = false,
        command?: any,
        commandArgs?: any
    ) => {
        event.preventDefault();
        event.stopPropagation();
        // If the user has tabbed to the end of the list, close the menu.
        switch (event.key) {
            case 'Tab':
                this.toggleShowMenu(event);
                break;
            case 'ArrowDown':
            case 'ArrowRight':
                if (index + 1 <= this.menuOptions.length - 1) {
                    if (
                        this.menuOptions[index + 1].type &&
                        this.menuOptions[index + 1].type ===
                            MenuItemTypeEnum.Separator
                    ) {
                        // skip separator items (they are non actionable items)
                        this.focusOptionIndex(index + 2);
                    } else {
                        this.focusOptionIndex(index + 1);
                    }
                } else {
                    this.focusOptionIndex(0);
                }
                break;
            case 'ArrowUp':
            case 'ArrowLeft':
                if (index - 1 >= 0) {
                    if (
                        this.menuOptions[index - 1].type &&
                        this.menuOptions[index - 1].type ===
                            MenuItemTypeEnum.Separator
                    ) {
                        // skip separator items (they are non actionable items)
                        this.focusOptionIndex(index - 2);
                    } else {
                        this.focusOptionIndex(index - 1);
                    }
                } else {
                    this.focusOptionIndex(this.menuOptions.length - 1);
                }
                break;
            case 'Escape':
                this.toggleShowMenu(event);
                break;
            case 'Enter':
                if (shouldToggle) {
                    this.toggleShowMenu(event, {
                        command: command(),
                        commandArgs: commandArgs
                    });
                } else {
                    // don't toggle, just execute the command
                    command(commandArgs);
                }
                break;
            default:
                break;
        }
    };
}
