import { Directive, HostListener, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

/**
 * @name SharedTrimEmailDirective
 * @description This attribute directive automatically trims whitespace at the
 * beginning and end of an input as the user types. This creates cross-browser
 * consistency when dealing with inputs of type="email" across Chrome, Firefox,
 * etc. Some browsers trim whitespace automatically, while others do not, which
 * results in validation problems.
 * */
@Directive({
    selector: '[sharedTrimEmail]'
})
export class SharedTrimEmailDirective {
    constructor() {}
    @Input() formControl: AbstractControl;
    @HostListener('input', ['$event']) trimInput(event) {
        this.formControl.setValue(this.formControl.value.trim() || '');
    }
}
