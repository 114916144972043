import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var SharedMyMessageService = /** @class */ (function () {
    function SharedMyMessageService() {
        this._showSuccessSubject = new Subject();
        this.showSuccess$ = this._showSuccessSubject.asObservable();
        this._showInfoSubject = new Subject();
        this.showInfo$ = this._showInfoSubject.asObservable();
        this._showWarnSubject = new Subject();
        this.showWarn$ = this._showWarnSubject.asObservable();
        this._showErrorSubject = new Subject();
        this.showError$ = this._showErrorSubject.asObservable();
        this._showCustomSubject = new Subject();
        this.showCustom$ = this._showCustomSubject.asObservable();
    }
    SharedMyMessageService.prototype.showSuccess = function (content) {
        if (content === void 0) { content = {
            summary: 'Success',
            detail: ''
        }; }
        this._showSuccessSubject.next({
            severity: 'success',
            summary: content.summary || 'Success',
            detail: content.detail
        });
    };
    SharedMyMessageService.prototype.showInfo = function (content) {
        if (content === void 0) { content = {
            summary: 'Info',
            detail: ''
        }; }
        this._showInfoSubject.next({
            severity: 'info',
            summary: content.summary || 'Info',
            detail: content.detail
        });
    };
    SharedMyMessageService.prototype.showWarn = function (content) {
        if (content === void 0) { content = {
            summary: 'Warning',
            detail: ''
        }; }
        this._showWarnSubject.next({
            severity: 'warn',
            summary: content.summary || 'Warning',
            detail: content.detail
        });
    };
    SharedMyMessageService.prototype.showError = function (content) {
        if (content === void 0) { content = {
            summary: 'Error',
            detail: ''
        }; }
        this._showErrorSubject.next({
            severity: 'error',
            summary: content.summary || 'Error',
            detail: content.detail
        });
    };
    SharedMyMessageService.prototype.showCustom = function (content) {
        if (content === void 0) { content = {
            summary: 'Info',
            detail: ''
        }; }
        this._showCustomSubject.next({
            key: 'custom',
            severity: 'info',
            summary: content.summary || 'Info',
            detail: content.detail
        });
    };
    SharedMyMessageService.ngInjectableDef = i0.defineInjectable({ factory: function SharedMyMessageService_Factory() { return new SharedMyMessageService(); }, token: SharedMyMessageService, providedIn: "root" });
    return SharedMyMessageService;
}());
export { SharedMyMessageService };
