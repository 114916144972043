<header>
  <button id="hamburger-button" #expandSidenavButton aria-label="Toggle navigation menu" type="button"
          *ngIf="hasSelectedInstitution()"
          (click)="emitToggleLeftMenu()"
          (keydown)="handleBlur($event)">
    <i class="eleot-icon icon-menu"></i>
  </button>
  <div class="img-container">
    <img src="assets/images/white-cognia-c.svg" alt="Cognia Logo">
  </div>

  <shared-my-menu id="user-menu" #myMenu
                  [toggleMenuButton]="toggleUserMenu"
                  [menuOptions]="items">
    <button id="user-action-menu-button" #toggleUserMenu [attr.aria-label]="'Toggle user action menu'"
            [attr.aria-expanded]="myMenu.showMenu"
            aria-haspopup="menu"
             [attr.aria-label]="'Welcome, ' + user.firstName + '. Toggle user action menu'" class="btn btn-styleless" type="button"
            (click)="myMenu.toggleShowMenu($event)">
      <span class="welcome-txt">Welcome</span>
      <span *ngIf="user" class="user-name-label">, {{user.firstName}}</span>
      <i class="fa fa-caret-down"></i>
    </button>
  </shared-my-menu>
</header>
