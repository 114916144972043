/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shared-aside-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared-aside-content.component";
import * as i3 from "../../services/sidenav.service";
var styles_SharedAsideContentComponent = [i0.styles];
var RenderType_SharedAsideContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SharedAsideContentComponent, data: { "animation": [{ type: 7, name: "viewState", definitions: [{ type: 0, name: "active", styles: { type: 6, styles: { transform: "translateX(0px)" }, offset: null }, options: undefined }, { type: 0, name: "inactive", styles: { type: 6, styles: { transform: "translateX(-{{width}})" }, offset: null }, options: { params: { width: "16.25rem" } } }, { type: 1, expr: "active => inactive", animation: { type: 4, styles: null, timings: "300ms ease-out" }, options: null }, { type: 1, expr: "inactive => active", animation: { type: 4, styles: null, timings: "300ms ease-in" }, options: null }], options: {} }] } });
export { RenderType_SharedAsideContentComponent as RenderType_SharedAsideContentComponent };
export function View_SharedAsideContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "aside", [["class", "aside-content"]], [[4, "width", null], [24, "@viewState", 0]], null, null, null, null)), i1.ɵpod(1, { width: 0 }), i1.ɵpod(2, { value: 0, params: 1 }), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; var currVal_1 = _ck(_v, 2, 0, (_co.open ? "active" : "inactive"), _ck(_v, 1, 0, _co.width)); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_SharedAsideContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-aside-content", [], null, null, null, View_SharedAsideContentComponent_0, RenderType_SharedAsideContentComponent)), i1.ɵdid(1, 114688, null, 0, i2.SharedAsideContentComponent, [i3.SidenavService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SharedAsideContentComponentNgFactory = i1.ɵccf("shared-aside-content", i2.SharedAsideContentComponent, View_SharedAsideContentComponent_Host_0, { open: "open", width: "width" }, {}, ["*"]);
export { SharedAsideContentComponentNgFactory as SharedAsideContentComponentNgFactory };
