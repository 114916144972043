import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthLoginComponent } from './components/auth-login/auth-login.component';
import { DialogModule } from 'primeng/primeng';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { SharedValidationService } from '../../shared/services/shared-validation.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { NoAuthShellComponent } from './no-auth-shell/no-auth-shell.component';
import { AuthRoutingModule } from '@modules/auth/auth-routing.module';

@NgModule({
    declarations: [
        AuthLoginComponent,
        ResetPasswordComponent,
        CreatePasswordComponent,
        NoAuthShellComponent
    ],
    imports: [
        // BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DialogModule,
        SharedModule,
        AuthRoutingModule
    ],
    providers: [SharedValidationService]
})
export class AuthModule {
    constructor() {}
}
