import {
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    Renderer2
} from '@angular/core';
import { SharedBaseMenuComponent } from '@shared/components/shared-base-menu/shared-base-menu.component';
import { SharedMyButtonDirective } from '@shared/directives/shared-my-button.directive';
import { MyMenuItem } from '@shared/classes/MyMenuItem.interface';
import { Observable, Subject } from 'rxjs';
import { SharedMenuService } from '@shared/services/shared-menu.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'shared-my-menu',
    templateUrl: './shared-my-menu.component.html',
    styleUrls: ['./shared-my-menu.component.scss']
})
export class SharedMyMenuComponent extends SharedBaseMenuComponent
    implements OnInit, OnDestroy {
    @Input() toggleMenuButton: HTMLButtonElement;
    showMenu: boolean = false;
    private _destroySubject: Subject<void> = new Subject<void>();
    destroy$: Observable<void> = this._destroySubject.asObservable();
    constructor(
        protected _renderer2: Renderer2,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _sharedMenuService: SharedMenuService
    ) {
        super(_renderer2, _changeDetectorRef);
    }

    ngOnInit(): void {
        this._sharedMenuService.closeAllMenus$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                if (this.showMenu) {
                    // We only call _closeMenu if the menu
                    // is open. Otherwise, there is a race condition between all
                    // Menus on the page where the last one's target button will
                    // be focused, rather than the target of the menu that was open.
                    this._closeMenu();
                }
            });
    }

    ngOnDestroy(): void {
        this._destroySubject.next();
        this._destroySubject.complete();
    }

    toggleShowMenu = (
        event: KeyboardEvent | MouseEvent,
        args: { command: any } = { command: null }
    ): void => {
        event.preventDefault();
        event.stopPropagation();
        // Toggles the View Resources menu
        this.showMenu = !this.showMenu;
        this._changeDetectorRef.detectChanges(); // Since we are accessing ViewChildren
        // which are contained inside of an ngIf, we must invoke changeDetection to
        // be able to detect the ViewChildren after the flag flips.
        if (!this.showMenu) {
            // re-focuses on the View Resources
            // button if the menu has just closed.
            const viewResourcesButton = this.toggleMenuButton;
            this._renderer2.setAttribute(viewResourcesButton, 'tabindex', '-1');
            if (viewResourcesButton) {
                setTimeout(() => {
                    // This setTimeout has to be present for the view to re-render and the .focus call to work.
                    viewResourcesButton.focus();
                    this._renderer2.removeAttribute(
                        viewResourcesButton,
                        'tabindex'
                    );
                }, 0);
            }
        } else {
            const buttons: SharedMyButtonDirective[] = this.myButtons.toArray();
            const enabledIndexArray: number[] = this.menuOptions
                .map((option: MyMenuItem, index: number) => {
                    return !option.disabled ? index : null;
                })
                .filter((myIndex: number) =>
                    myIndex ? myIndex : myIndex === 0 ? true : false
                );
            if (buttons[enabledIndexArray[0]]) {
                buttons[enabledIndexArray[0]].elementRef.nativeElement.focus();
            }
        }
        if (args.command) {
            args.command();
        }
    };

    handleBlur = (event: FocusEvent) => {
        this.toggleShowMenu((event as unknown) as MouseEvent);
    };

    _closeMenu = () => {
        // Toggles the View Resources menu
        this.showMenu = false;
        this._changeDetectorRef.detectChanges(); // Since we are accessing ViewChildren
        // which are contained inside of an ngIf, we must invoke changeDetection to
        // be able to detect the ViewChildren after the flag flips.

        // Re-focuses on the View Resources
        // button since the menu has just closed.
        const viewResourcesButton = this.toggleMenuButton;
        this._renderer2.setAttribute(viewResourcesButton, 'tabindex', '-1');
        if (viewResourcesButton) {
            setTimeout(() => {
                // This setTimeout has to be present for the view to re-render and the .focus call to work.
                viewResourcesButton.focus();
                this._renderer2.removeAttribute(
                    viewResourcesButton,
                    'tabindex'
                );
            }, 0);
        }
    };
}
