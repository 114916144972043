/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shared-my-cdk-popover.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./shared-my-cdk-popover.component";
import * as i4 from "../../classes/my-cdk-popover-ref";
var styles_SharedMyCdkPopoverComponent = [i0.styles];
var RenderType_SharedMyCdkPopoverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SharedMyCdkPopoverComponent, data: {} });
export { RenderType_SharedMyCdkPopoverComponent as RenderType_SharedMyCdkPopoverComponent };
function View_SharedMyCdkPopoverComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content; _ck(_v, 0, 0, currVal_0); }); }
function View_SharedMyCdkPopoverComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_SharedMyCdkPopoverComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedMyCdkPopoverComponent_3)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.context; var currVal_1 = _co.content; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_SharedMyCdkPopoverComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_SharedMyCdkPopoverComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedMyCdkPopoverComponent_5)), i1.ɵdid(2, 671744, null, 0, i2.NgComponentOutlet, [i1.ViewContainerRef], { ngComponentOutlet: [0, "ngComponentOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SharedMyCdkPopoverComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "popover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedMyCdkPopoverComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedMyCdkPopoverComponent_2)), i1.ɵdid(6, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedMyCdkPopoverComponent_4)), i1.ɵdid(8, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.renderMethod; _ck(_v, 2, 0, currVal_0); var currVal_1 = "text"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "template"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "component"; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_SharedMyCdkPopoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-my-cdk-popover", [], null, null, null, View_SharedMyCdkPopoverComponent_0, RenderType_SharedMyCdkPopoverComponent)), i1.ɵdid(1, 114688, null, 0, i3.SharedMyCdkPopoverComponent, [i4.MyCdkPopoverRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SharedMyCdkPopoverComponentNgFactory = i1.ɵccf("shared-my-cdk-popover", i3.SharedMyCdkPopoverComponent, View_SharedMyCdkPopoverComponent_Host_0, {}, {}, []);
export { SharedMyCdkPopoverComponentNgFactory as SharedMyCdkPopoverComponentNgFactory };
