/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shared-header-smart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../presentation-components/shared-header-presentation/shared-header-presentation.component.ngfactory";
import * as i3 from "../../presentation-components/shared-header-presentation/shared-header-presentation.component";
import * as i4 from "../../../modules/auth/services/auth.service";
import * as i5 from "../../../modules/auth/services/auth-user.service";
import * as i6 from "../../services/shared-institution-data-store.service";
import * as i7 from "../../services/sidenav.service";
import * as i8 from "@angular/common";
import * as i9 from "./shared-header-smart.component";
import * as i10 from "@angular/router";
import * as i11 from "../../../modules/institution/services/institution.service";
var styles_SharedHeaderSmartComponent = [i0.styles];
var RenderType_SharedHeaderSmartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SharedHeaderSmartComponent, data: {} });
export { RenderType_SharedHeaderSmartComponent as RenderType_SharedHeaderSmartComponent };
export function View_SharedHeaderSmartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "shared-header-presentation", [], null, [[null, "goBackEmitter"], [null, "toggleLeftMenu"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("goBackEmitter" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } if (("toggleLeftMenu" === en)) {
        var pd_1 = (_co.emitToggleLeftMenu() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_SharedHeaderPresentationComponent_0, i2.RenderType_SharedHeaderPresentationComponent)), i1.ɵdid(1, 245760, null, 0, i3.SharedHeaderPresentationComponent, [i4.AuthService, i5.AuthUserService, i6.SharedInstitutionDataStore, i7.SidenavService, i1.Renderer2], { institutionId: [0, "institutionId"], user: [1, "user"] }, { goBackEmitter: "goBackEmitter", toggleLeftMenu: "toggleLeftMenu" }), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedInstitutionId(); var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform(_co.user$)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_SharedHeaderSmartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-header-smart", [], null, null, null, View_SharedHeaderSmartComponent_0, RenderType_SharedHeaderSmartComponent)), i1.ɵdid(1, 245760, null, 0, i9.SharedHeaderSmartComponent, [i10.Router, i5.AuthUserService, i11.InstitutionService, i6.SharedInstitutionDataStore, i8.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SharedHeaderSmartComponentNgFactory = i1.ɵccf("shared-header-smart", i9.SharedHeaderSmartComponent, View_SharedHeaderSmartComponent_Host_0, {}, { toggleLeftMenu: "toggleLeftMenu" }, []);
export { SharedHeaderSmartComponentNgFactory as SharedHeaderSmartComponentNgFactory };
