export interface IPasswordValidations {
    eightChar: boolean;
    oneLowercase: boolean;
    oneUppercase: boolean;
    oneNumber: boolean;
    oneSpecialChar: boolean;
    noSpaces: boolean;
}

export interface IPasswordValidator {
    eightChar: boolean;
    oneLowercase: boolean;
    oneUppercase: boolean;
    oneNumber: boolean;
    oneSpecialChar: boolean;
    noSpaces: boolean;
    reset(): void;
    setAllValidations(validations: IPasswordValidations): void;
    checkAllValidations(): boolean;
}

export class PasswordValidator implements IPasswordValidator {
    eightChar: boolean;
    oneLowercase: boolean;
    oneUppercase: boolean;
    oneNumber: boolean;
    oneSpecialChar: boolean;
    noSpaces: boolean;

    constructor() {
        this.reset();
    }

    public setAllValidations(validations: IPasswordValidations): void {
        this.eightChar = validations.eightChar;
        this.oneLowercase = validations.oneLowercase;
        this.oneUppercase = validations.oneUppercase;
        this.oneNumber = validations.oneNumber;
        this.oneSpecialChar = validations.oneSpecialChar;
        this.noSpaces = validations.noSpaces;
    }

    public checkAllValidations() {
        return (
            this.eightChar &&
            this.oneLowercase &&
            this.oneUppercase &&
            this.oneNumber &&
            this.oneSpecialChar &&
            this.noSpaces
        );
    }

    public reset(): void {
        this.eightChar = true;
        this.oneLowercase = true;
        this.oneUppercase = true;
        this.oneNumber = true;
        this.oneSpecialChar = true;
        this.noSpaces = true;
    }
}
