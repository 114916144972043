<div class="auth-content">
  <div class="my-flex-container">
    <h1>{{!resetRequestSubmitted ? 'Request Password' : 'Password Requested'}}</h1>
    <ng-template [ngIf]="!resetRequestSubmitted" [ngIfElse]="submissionResponse">
      <div class="subtext">
        Please enter the email address associated with your account.
      </div>
      <form (ngSubmit)="onSubmit()" [formGroup]="resetForm">
        <div class="error-message" *ngIf="formErrMsg">
          <i class="fa fa-exclamation-triangle"></i>
          <span class="message-text">{{formErrMsg}}</span>
        </div>
        <label for="reset-password-email">Email Address</label>
        <div class="input-wrapper">
          <input id="reset-password-email" [ngClass]="{'invalid': controlInvalid(resetForm.controls['email'])}"
                 type="email" name="email" [formControl]="resetForm.controls.email" sharedTrimEmail>
          <app-error-msg
            [control]="resetForm.controls['email']"
            [show]="controlInvalid(resetForm.controls['email'])">
          </app-error-msg>
        </div>
        <button class="btn" type="submit" [disabled]="submitting">Request Password</button>
        <a class="pull-left auth-link" routerLink="/login">Log In</a>
        <a class="pull-right auth-link" href="mailto:contactus@cognia.org?subject=Cognia%20assessments%20question">Contact
          us for login help</a>
        <!--      For IE11 -->
        <div class="add-bottom-margin"></div>
      </form>
    </ng-template>
    <ng-template #submissionResponse>
      <div class="subtext">
        <p>If a user account exists for the email address provided, a reset password link has been emailed to you.</p>
        <p class="didnt-receive">Didn't receive an email?</p>
        <p>
          Be sure to check junk/spam folders or
          <a class="auth-link" href="mailto:contactus@cognia.org?subject=Cognia%20assessments%20question">Contact
            us for login help.</a>
        </p>
        <!--      For IE11 -->
        <div class="add-bottom-margin"></div>
      </div>
    </ng-template>
  </div>
</div>

