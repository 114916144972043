import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { SharedValidationService } from '@shared/services/shared-validation.service';

@Component({
    selector: 'app-error-msg',
    templateUrl: './app-error-msg.component.html',
    styleUrls: ['./app-error-msg.component.scss']
})
export class AppErrorMsgComponent implements OnInit, OnChanges {
    @Input() control: FormControl;
    @Input() show: boolean;

    constructor(private validationService: SharedValidationService) {}

    get hasWarnings() {
        return this.control.errors && this.control.errors.warn;
    }

    get styleClass() {
        if (!this.show) {
            return 'error-message';
        } else if (this.hasWarnings) {
            return 'warn-message';
        } else {
            return 'error-message';
        }
    }

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        this.show = changes.show ? changes.show.currentValue : false;
    }

    get errorMessage() {
        for (const propertyName in this.control.errors) {
            if (
                this.control.errors.hasOwnProperty(propertyName) &&
                this.control.errors[propertyName]
            ) {
                switch (propertyName) {
                    case 'maxlength':
                        return this.validationService.getMaxLengthErrorMessage(
                            this.control.errors[propertyName].requiredLength
                        );
                    case 'min':
                        return this.validationService.getMinNumberErrorMessage(
                            this.control.errors[propertyName].min
                        );
                    default:
                        return this.validationService.getValidatorErrorMessage(
                            propertyName
                        );
                }
            }
        }
        return null;
    }
}
