<aside aria-labelledby="skip-to-main-content"><a id="skip-to-main-content" aria-label="Skip to main content." #skipToMain [routerLink]="['./']" fragment="main" (click)="goToMain()" (keydown)="handleTab($event)">Skip to Main Content</a></aside>
<shared-aside-content [open]="showAside" [width]="asideWidth">
  <shared-left-menu-smart (hide)="showAside = false"></shared-left-menu-smart>
</shared-aside-content>
<shared-main-content (overlayClose)="showAside = false" [showAside]="showAside" [asideWidth]="asideWidth"
                     [overlay]="showAside">
  <div class="overlay pointer" *ngIf="showAside" (click)="onOverlayClick()"></div>
  <shared-header-smart (toggleLeftMenu)="toggleLeftMenu()"></shared-header-smart>
  <shared-toaster></shared-toaster>
  <div class="main-container" cdkScrollable [ngClass]="{'no-scroll-y': showAside || popoverOpen}">
    <main #main id="main">
      <router-outlet></router-outlet>
    </main>
  </div>
</shared-main-content>
