import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, RouterEvent } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
// import {ReportingDataStoreService} from '@modules/reporting/services/reporting-data-store.service';
import { User } from '@shared/classes/user';
import { AuthUserService } from '@modules/auth/services/auth-user.service';

@Component({
    selector: 'shared-noauth-header-smart',
    templateUrl: './shared-noauth-header-smart.component.html',
    styleUrls: ['./shared-noauth-header-smart.component.scss']
})
export class SharedNoauthHeaderSmartComponent implements OnInit, OnDestroy {
    reportId: number;
    user$: Observable<User>;

    private routerEventSubscription: Subscription;
    private lastRouterEvent: string;
    private _titleSubject: BehaviorSubject<string> = new BehaviorSubject<
        string
    >('Login');
    title$: Observable<string> = this._titleSubject.asObservable();

    constructor(
        public router: Router,
        private _authUserService: AuthUserService,
        public location: Location
    ) {}

    ngOnInit() {
        this.user$ = this._authUserService.user$;
        if (!this.routerEventSubscription) {
            this.routerEventSubscription = this.router.events.subscribe(
                (event: RouterEvent) => {
                    this.lastRouterEvent = this.router.url.toString();
                    this.setTitle();
                }
            );
        }
    }

    ngOnDestroy() {
        if (this.routerEventSubscription) {
            this.routerEventSubscription.unsubscribe();
        }
    }

    goBack = (): void => {
        this.location.back();
    };

    setTitle() {
        if (!this.lastRouterEvent) {
            this._titleSubject.next('Login');
        } else {
            switch (this.lastRouterEvent) {
                case '/reset-password':
                    this._titleSubject.next('Request Password');
                    break;
                case '/create-password':
                    this._titleSubject.next('Create Password');
                    break;
                default:
                    this._titleSubject.next('Login');
                    break;
            }
        }
    }
}
