/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shared-noauth-header-smart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../presentation-components/shared-noauth-header-presentation/shared-noauth-header-presentation.component.ngfactory";
import * as i3 from "../../presentation-components/shared-noauth-header-presentation/shared-noauth-header-presentation.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../modules/auth/services/auth.service";
import * as i6 from "../../../modules/auth/services/auth-user.service";
import * as i7 from "@angular/common";
import * as i8 from "./shared-noauth-header-smart.component";
var styles_SharedNoauthHeaderSmartComponent = [i0.styles];
var RenderType_SharedNoauthHeaderSmartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SharedNoauthHeaderSmartComponent, data: {} });
export { RenderType_SharedNoauthHeaderSmartComponent as RenderType_SharedNoauthHeaderSmartComponent };
export function View_SharedNoauthHeaderSmartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "shared-noauth-header-presentation", [], null, [[null, "goBackEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("goBackEmitter" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SharedNoauthHeaderPresentationComponent_0, i2.RenderType_SharedNoauthHeaderPresentationComponent)), i1.ɵdid(1, 638976, null, 0, i3.SharedNoauthHeaderPresentationComponent, [i4.Router, i5.AuthService, i6.AuthUserService], { title: [0, "title"], user: [1, "user"] }, { goBackEmitter: "goBackEmitter" }), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.title$)); var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 3).transform(_co.user$)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_SharedNoauthHeaderSmartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-noauth-header-smart", [], null, null, null, View_SharedNoauthHeaderSmartComponent_0, RenderType_SharedNoauthHeaderSmartComponent)), i1.ɵdid(1, 245760, null, 0, i8.SharedNoauthHeaderSmartComponent, [i4.Router, i6.AuthUserService, i7.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SharedNoauthHeaderSmartComponentNgFactory = i1.ɵccf("shared-noauth-header-smart", i8.SharedNoauthHeaderSmartComponent, View_SharedNoauthHeaderSmartComponent_Host_0, {}, {}, []);
export { SharedNoauthHeaderSmartComponentNgFactory as SharedNoauthHeaderSmartComponentNgFactory };
