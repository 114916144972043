import {
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild
} from '@angular/core';
import { ToastService } from '@shared/services/toast.service';
import { Observable, Subject } from 'rxjs';
import { SidenavService } from '@shared/services/sidenav.service';
import { take, takeUntil } from 'rxjs/operators';
import { SharedMenuService } from '@shared/services/shared-menu.service';
import { SharedCdkPopoverMessageService } from '@shared/services/shared-cdk-popover-message.service';

@Component({
    selector: 'aefa-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {
    showAside: boolean = false;
    asideWidth: string = '17.5rem';
    popoverOpen: boolean = false;
    @ViewChild('skipToMain') skipToMainAnchor: ElementRef;
    @ViewChild('main') main: ElementRef;
    private _destroySubject: Subject<void> = new Subject<void>();
    destroy$: Observable<void> = this._destroySubject.asObservable();

    constructor(
        private _toastService: ToastService,
        private _sidenavService: SidenavService,
        private _renderer2: Renderer2,
        private _sharedMenuService: SharedMenuService,
        private _sharedCdkPopoverMessageService: SharedCdkPopoverMessageService
    ) {}

    @HostListener('click', ['$event']) closeMenus() {
        // EAF-166. This makes sure that open popover menus, etc. close
        // whenever the user clicks somewhere on the screen.
        this._sharedMenuService.closeAllMenus();
    }

    ngOnInit() {
        this._toastService.purgeToast();

        this._sidenavService.showAside$
            .pipe(takeUntil(this.destroy$))
            .subscribe((flag: boolean) => {
                this.showAside = flag;
            });

        this._sidenavService.focusSkipToMainAnchor$
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                this._focusElement(this.skipToMainAnchor);
            });

        this._sharedCdkPopoverMessageService.popoverOpenClosedEvent$
            .pipe(takeUntil(this.destroy$))
            .subscribe((openClosed: 'OPEN' | 'CLOSED') => {
                if (openClosed === this._sharedCdkPopoverMessageService.OPEN) {
                    this.popoverOpen = true;
                } else {
                    this.popoverOpen = false;
                }
            });
    }

    ngOnDestroy(): void {
        this._destroySubject.next(null);
        this._destroySubject.complete();
    }

    toggleLeftMenu = (): void => {
        this._sidenavService.setShowAside(!this.showAside);
    };

    onOverlayClick = (): void => {
        this._sidenavService.setShowAside(false);
    };

    goToMain = () => {
        this._sidenavService.showAside$
            .pipe(
                take(1),
                takeUntil(this.destroy$)
            )
            .subscribe((flag: boolean) => {
                if (flag) {
                    this._sidenavService.setShowAside(false);
                    this._focusElement(this.main);
                } else {
                    this._focusElement(this.main);
                }
            });
    };

    handleTab = (event: KeyboardEvent) => {
        if (event.key === 'Tab' && !event.shiftKey) {
            if (!this.showAside) {
                this._sidenavService.focusExpandSidenavButton();
            }
        }
    };

    private _focusElement = (elementRef: ElementRef) => {
        this._renderer2.setAttribute(
            elementRef.nativeElement,
            'tabindex',
            '-1'
        );
        setTimeout(() => {
            elementRef.nativeElement.focus();
            this._renderer2.removeAttribute(
                elementRef.nativeElement,
                'tabindex'
            );
        }, 0);
    };
}
