import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { AuthService } from '@modules/auth/services/auth.service';
import { AuthUserService } from '@modules/auth/services/auth-user.service';
import { User } from '@shared/classes/user';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
    selector: 'shared-noauth-header-presentation',
    templateUrl: './shared-noauth-header-presentation.component.html',
    styleUrls: ['./shared-noauth-header-presentation.component.scss']
})
export class SharedNoauthHeaderPresentationComponent
    implements OnInit, OnChanges {
    @Input() title: string;
    staticTitle: string;
    @Input() showMenu: boolean;
    @Input() detailId: number;
    @Output() goBackEmitter: EventEmitter<null> = new EventEmitter();

    @Input() user: User;
    items: [MenuItem];

    constructor(
        private _router: Router,
        private _authService: AuthService,
        private _authUserService: AuthUserService
    ) {}

    emitGoBack = (): void => {
        this.goBackEmitter.emit(null);
    };

    logout = (): void => {
        this._authService.logout();
    };

    ngOnInit() {
        this.items = [
            { icon: 'fa fa-sign-out', label: 'Logout', command: this.logout }
        ];
        const url = this._router.url.toString();
        if (!url) {
            this.staticTitle = 'Login';
        } else {
            switch (url) {
                case '/reset-password':
                    this.staticTitle = 'Request Password';
                    break;
                case '/create-password':
                    this.staticTitle = 'Create Password';
                    break;
                default:
                    this.staticTitle = 'Login';
                    break;
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {}
}
