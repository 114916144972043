import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShellComponent } from './shell/shell.component';
import { AssessmentsAuthGuard } from '@app/guards/assessments-auth.guard';
import { NoAuthShellComponent } from '@modules/auth/no-auth-shell/no-auth-shell.component';
import { VersionComponent } from '@app/version/version.component';

const routes: Routes = [
    {
        path: 'login',
        component: NoAuthShellComponent,
        loadChildren: '@modules/auth/auth.module#AuthModule'
    },
    { path: 'version', component: VersionComponent },
    {
        path: '',
        component: ShellComponent,
        canActivate: [AssessmentsAuthGuard], // will redirect non authorized users to /login
        canActivateChild: [AssessmentsAuthGuard],
        loadChildren:
            '@modules/institution/institution.module#InstitutionModule'
    },
    {
        path: '**',
        redirectTo: 'login'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            enableTracing: false,
            anchorScrolling: 'enabled'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
