import { Component, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators
} from '@angular/forms';
import { SharedValidationService } from '@shared/services/shared-validation.service';
import { Router } from '@angular/router';
import { AuthService } from '@modules/auth/services/auth.service';
import { ToastService } from '@shared/services/toast.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'auth-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    public resetForm: FormGroup;
    public formErrMsg: string;
    public resetRequestSubmitted: boolean;
    submitted = false;
    submitting = false;

    constructor(
        private _router: Router,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private validationService: SharedValidationService,
        private _toastService: ToastService
    ) {
        this.resetForm = this.formBuilder.group({
            email: [
                '',
                [
                    this.validationService.emailValidator,
                    Validators.email,
                    this.validationService.spaceChecker
                ]
            ]
        });
    }

    ngOnInit() {
        this.resetForm.valueChanges.subscribe(data => (this.formErrMsg = null));
    }

    onSubmit() {
        this.submitted = true;
        this.resetForm.controls.email.markAsTouched();
        if (!this.resetForm.valid) {
            return;
        }
        this.submitting = true;
        this.authService
            .forgotPassword(this.resetForm.value.email)
            .pipe(
                finalize(() => {
                    this.submitting = false;
                })
            )
            .subscribe(
                response => {
                    // this.loading = false;
                    this.resetRequestSubmitted = true;
                },
                error => {
                    // this.loading = false;
                    if (error && error.status && error.status === 500) {
                        // this.resetRequestSubmitted = true;
                        this.formErrMsg = error.error.errorMessage;
                        this._toastService.queueFatalToastWithMessage(
                            `There was an issue submitting your request. Please try again`
                        );
                    } else {
                        this.resetRequestSubmitted = true;
                    }
                }
            );
    }

    controlInvalid = (
        formControl: AbstractControl,
        showErrorIfPristine: boolean = false
    ) => {
        return (
            ((formControl.touched &&
                (showErrorIfPristine ? true : formControl.dirty)) ||
                this.submitted) &&
            formControl.invalid
        );
    };
}
