export enum ToastType {
    NORMAL = 0,
    CAUTION = 1,
    ERROR = 2,
    FATAL = 3,
    BLANK = 99
}

export interface IToastMessage {
    id?: number;
    iconRef?: string;
    messageHtml: string;
    duration: number; // in ms
    style: ToastType;
}
