import { throwError } from 'rxjs';
import { AuthService } from '@modules/auth/services/auth.service';
import { catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor(_authService) {
        this._authService = _authService;
    }
    AuthInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        var token = this._authService.getToken();
        if (token &&
            req.url.indexOf('login') < 0 &&
            req.url.indexOf('amazonaws.com') < 0 // AWS only allows the query param
        // token OR an Authorization header to be used. We stick with the query
        // params token.
        ) {
            req = req.clone({
                headers: req.headers.set('Authorization', token)
            });
        }
        if (!req.headers.has('Content-Type') &&
            req.url.indexOf('bulkUsers') < 0) {
            req = req.clone({
                headers: req.headers.set('Content-Type', 'application/json')
            });
        }
        return next.handle(req).pipe(map(function (event) {
            return event;
        }), catchError(function (error) {
            if (error.status &&
                (error.status === 401 || error.status === 0)) {
                _this._authService.logoutOnClientOnly();
            }
            return throwError(error);
        }));
    };
    AuthInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function AuthInterceptor_Factory() { return new AuthInterceptor(i0.inject(i1.AuthService)); }, token: AuthInterceptor, providedIn: "root" });
    return AuthInterceptor;
}());
export { AuthInterceptor };
