import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharedMenuService {
    private _closeAllMenusSubject: Subject<void> = new Subject<void>();
    closeAllMenus$: Observable<
        void
    > = this._closeAllMenusSubject.asObservable();
    constructor() {}

    closeAllMenus = () => {
        this._closeAllMenusSubject.next();
    };
}
