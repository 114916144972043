/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-auth-shell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/smart-components/shared-noauth-header-smart/shared-noauth-header-smart.component.ngfactory";
import * as i3 from "../../../shared/smart-components/shared-noauth-header-smart/shared-noauth-header-smart.component";
import * as i4 from "@angular/router";
import * as i5 from "../services/auth-user.service";
import * as i6 from "@angular/common";
import * as i7 from "../../../shared/components/toaster/toaster.component.ngfactory";
import * as i8 from "../../../shared/components/toaster/toaster.component";
import * as i9 from "../../../shared/services/toast.service";
import * as i10 from "./no-auth-shell.component";
var styles_NoAuthShellComponent = [i0.styles];
var RenderType_NoAuthShellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoAuthShellComponent, data: {} });
export { RenderType_NoAuthShellComponent as RenderType_NoAuthShellComponent };
export function View_NoAuthShellComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-noauth-header-smart", [], null, null, null, i2.View_SharedNoauthHeaderSmartComponent_0, i2.RenderType_SharedNoauthHeaderSmartComponent)), i1.ɵdid(1, 245760, null, 0, i3.SharedNoauthHeaderSmartComponent, [i4.Router, i5.AuthUserService, i6.Location], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "shared-toaster", [], null, null, null, i7.View_ToasterComponent_0, i7.RenderType_ToasterComponent)), i1.ɵdid(3, 114688, null, 0, i8.ToasterComponent, [i9.ToastService], null, null), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
export function View_NoAuthShellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "auth-no-auth-shell", [], null, null, null, View_NoAuthShellComponent_0, RenderType_NoAuthShellComponent)), i1.ɵdid(1, 114688, null, 0, i10.NoAuthShellComponent, [i9.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoAuthShellComponentNgFactory = i1.ɵccf("auth-no-auth-shell", i10.NoAuthShellComponent, View_NoAuthShellComponent_Host_0, {}, {}, []);
export { NoAuthShellComponentNgFactory as NoAuthShellComponentNgFactory };
