import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    CanActivateChild,
    CanLoad,
    Route,
    UrlSegment,
    ActivatedRoute
} from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '@modules/auth/services/auth.service';
import { catchError, take, map } from 'rxjs/operators';
import { User } from '@shared/classes/user';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class AssessmentsAuthGuard
    implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        private _authService: AuthService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _location: Location
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this._authenticateUser(next);
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this._authenticateUser(next);
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this._authService.isAuthorized()) {
            return true;
        } else {
            this._router.navigate(['login']);
            return false;
        }
    }

    _authenticateUser = (next: ActivatedRouteSnapshot) => {
        // If the auth token is passed in URL, check if there's already one in our local storage.
        // If token in our local is different then the one passed in URL, clear the old one, so that the new user can be logged in
        if (
            next.queryParamMap.get('authToken') &&
            this._authService.getToken() &&
            next.queryParamMap.get('authToken') !== this._authService.getToken()
        ) {
            // clear current session info, a new user is trying to log in
            this._authService.clearUserSession();
        }
        if (this._authService.isAuthorized()) {
            // If there is an auth token in the query params of the route
            // we must strip it out.
            if (next.queryParamMap.get('authToken')) {
                this._stripAuthTokenResetHistoryAndNavigate();
                return false; // We block navigation to original route with
                // AuthToken in params, and instead nav to new route with
                // AuthToken stripped out.
            }
            // Else proceed.
            return true;
        } else if (
            !this._authService.isAuthorized() &&
            next.queryParamMap.get('authToken')
        ) {
            const token: string = next.queryParamMap.get('authToken');
            const customerId: string = next.firstChild.paramMap.get(
                'institutionId'
            );
            return this._authService.loginByToken(token, customerId).pipe(
                map((res: { token: string; user: User }) => {
                    this._stripAuthTokenResetHistoryAndNavigate();
                    return false; // We block navigation to original route with
                    // AuthToken in params, and instead nav to new route with
                    // AuthToken stripped out.
                }),
                take(1),
                catchError((err: any) => {
                    return throwError(err);
                })
            );
        } else if (
            !this._authService.isAuthorized() &&
            !next.queryParamMap.get('authToken')
        ) {
            this._authService.logoutOnClientOnly();
            return false;
        } else {
            this._authService.logoutOnClientOnly();
            return false;
        }
    };

    _stripAuthTokenResetHistoryAndNavigate = (): string => {
        const replacementPath: string = this._location
            .path()
            .replace(/(([?|&]authToken=[^&]+))/gi, '');
        this._router.navigate([replacementPath]);
        this._location.replaceState(replacementPath); // Strip token from path and replace URL history.
        return replacementPath;
    };
}
