import { BehaviorSubject, Subject } from 'rxjs';
import { AuthService } from '@modules/auth/services/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../../modules/auth/services/auth.service";
var SidenavService = /** @class */ (function () {
    function SidenavService(_authService) {
        var _this = this;
        this._authService = _authService;
        this._showAsideBehaviorSubject = new BehaviorSubject(false);
        this._focusSkipToMainAnchorSubject = new Subject();
        this.focusSkipToMainAnchor$ = this._focusSkipToMainAnchorSubject.asObservable();
        this._focusExpandSidenavButtonSubject = new Subject();
        this.focusExpandSidenavButton$ = this._focusExpandSidenavButtonSubject.asObservable();
        this.showAside$ = this._showAsideBehaviorSubject.asObservable();
        this.setShowAside = function (flag) {
            _this._showAsideBehaviorSubject.next(flag);
        };
        this.focusExpandSidenavButton = function () {
            _this._focusExpandSidenavButtonSubject.next();
        };
        this.focusSkipToMainAnchor = function () {
            _this._focusSkipToMainAnchorSubject.next();
        };
        this._authService.clearUserData$.subscribe(function () {
            _this.setShowAside(false);
        });
    }
    SidenavService.ngInjectableDef = i0.defineInjectable({ factory: function SidenavService_Factory() { return new SidenavService(i0.inject(i1.AuthService)); }, token: SidenavService, providedIn: "root" });
    return SidenavService;
}());
export { SidenavService };
