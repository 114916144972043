import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthUserService } from '@modules/auth/services/auth-user.service';
import { SharedInstitutionDataStore } from '@shared/services/shared-institution-data-store.service';
import { User } from '@shared/classes/user';
import { Institution } from '@shared/classes/institution';
import { Observable } from 'rxjs';

@Component({
    selector: 'shared-left-menu-smart',
    templateUrl: './shared-left-menu-smart.component.html',
    styleUrls: ['./shared-left-menu-smart.component.scss']
})
export class SharedLeftMenuSmartComponent implements OnInit {
    user$: Observable<User> = this._authUserService.user$;
    selectedInstitution$: Observable<Institution> = this
        ._sharedInstitutionDataStore.selectedInstitution$;
    @Output() hide: EventEmitter<void> = new EventEmitter<void>();
    constructor(
        private _authUserService: AuthUserService,
        private _sharedInstitutionDataStore: SharedInstitutionDataStore
    ) {}

    ngOnInit() {}

    hideMenu() {
        this.hide.emit();
    }
}
