import { OnInit } from '@angular/core';
import { ToastService } from '@shared/services/toast.service';
import { ToastType } from '@shared/models/IToastMessage';
var ToasterComponent = /** @class */ (function () {
    function ToasterComponent(_toastService) {
        this._toastService = _toastService;
        this._localToastQueue = [];
    }
    ToasterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._toastSubscription = this._toastService.toastQueue$.subscribe(function (toastMsg) {
            if (toastMsg && toastMsg.style !== ToastType.BLANK) {
                _this._localToastQueue = [
                    toastMsg
                    // ...this._localToastQueue Removed in EAF-136 due to excessive toast messages.
                    // Only one toast should show at a time.
                ];
                _this._toastService.purgeToast();
            }
        });
    };
    Object.defineProperty(ToasterComponent.prototype, "availableToasts", {
        get: function () {
            return this._localToastQueue;
        },
        enumerable: true,
        configurable: true
    });
    ToasterComponent.prototype.trackToasts = function (index, toast) {
        return toast ? toast.id : undefined;
    };
    ToasterComponent.prototype.expireToast = function (idToExpire) {
        this._localToastQueue = this._localToastQueue.filter(function (toast) {
            return toast.id !== idToExpire;
        });
        // this._toastService.purgeToast();
    };
    return ToasterComponent;
}());
export { ToasterComponent };
