/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shared-header-presentation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../components/shared-my-menu/shared-my-menu.component.ngfactory";
import * as i4 from "../../components/shared-my-menu/shared-my-menu.component";
import * as i5 from "../../services/shared-menu.service";
import * as i6 from "./shared-header-presentation.component";
import * as i7 from "../../../modules/auth/services/auth.service";
import * as i8 from "../../../modules/auth/services/auth-user.service";
import * as i9 from "../../services/shared-institution-data-store.service";
import * as i10 from "../../services/sidenav.service";
var styles_SharedHeaderPresentationComponent = [i0.styles];
var RenderType_SharedHeaderPresentationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SharedHeaderPresentationComponent, data: {} });
export { RenderType_SharedHeaderPresentationComponent as RenderType_SharedHeaderPresentationComponent };
function View_SharedHeaderPresentationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["expandSidenavButton", 1]], null, 1, "button", [["aria-label", "Toggle navigation menu"], ["id", "hamburger-button"], ["type", "button"]], null, [[null, "click"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.emitToggleLeftMenu() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (_co.handleBlur($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "eleot-icon icon-menu"]], null, null, null, null, null))], null, null); }
function View_SharedHeaderPresentationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "user-name-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [", ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user.firstName; _ck(_v, 1, 0, currVal_0); }); }
export function View_SharedHeaderPresentationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { expandSidenavButton: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 12, "header", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedHeaderPresentationComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "img-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "Cognia Logo"], ["src", "assets/images/white-cognia-c.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, "shared-my-menu", [["id", "user-menu"]], null, null, null, i3.View_SharedMyMenuComponent_0, i3.RenderType_SharedMyMenuComponent)), i1.ɵdid(7, 245760, [["myMenu", 4]], 0, i4.SharedMyMenuComponent, [i1.Renderer2, i1.ChangeDetectorRef, i5.SharedMenuService], { menuOptions: [0, "menuOptions"], toggleMenuButton: [1, "toggleMenuButton"] }, null), (_l()(), i1.ɵeld(8, 0, [["toggleUserMenu", 1]], 0, 5, "button", [["aria-haspopup", "menu"], ["class", "btn btn-styleless"], ["id", "user-action-menu-button"], ["type", "button"]], [[1, "aria-label", 0], [1, "aria-expanded", 0], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).toggleShowMenu($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "welcome-txt"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Welcome"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedHeaderPresentationComponent_2)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["class", "fa fa-caret-down"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasSelectedInstitution(); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.items; var currVal_2 = i1.ɵnov(_v, 8); _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_6 = _co.user; _ck(_v, 12, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = "Toggle user action menu"; var currVal_4 = i1.ɵnov(_v, 7).showMenu; var currVal_5 = (("Welcome, " + _co.user.firstName) + ". Toggle user action menu"); _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5); }); }
export function View_SharedHeaderPresentationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-header-presentation", [], null, null, null, View_SharedHeaderPresentationComponent_0, RenderType_SharedHeaderPresentationComponent)), i1.ɵdid(1, 245760, null, 0, i6.SharedHeaderPresentationComponent, [i7.AuthService, i8.AuthUserService, i9.SharedInstitutionDataStore, i10.SidenavService, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SharedHeaderPresentationComponentNgFactory = i1.ɵccf("shared-header-presentation", i6.SharedHeaderPresentationComponent, View_SharedHeaderPresentationComponent_Host_0, { title: "title", showMenu: "showMenu", detailId: "detailId", institutionId: "institutionId", user: "user" }, { goBackEmitter: "goBackEmitter", toggleLeftMenu: "toggleLeftMenu" }, []);
export { SharedHeaderPresentationComponentNgFactory as SharedHeaderPresentationComponentNgFactory };
