import { Component, OnInit, TemplateRef } from '@angular/core';
import {
    MyCdkPopoverRef,
    PopoverContent
} from '@shared/classes/my-cdk-popover-ref';
import { MyCdkPopoverService } from '@shared/services/my-cdk-popover.service';
import { SharedCdkPopoverMessageService } from '@shared/services/shared-cdk-popover-message.service';

@Component({
    selector: 'shared-my-cdk-popover',
    templateUrl: './shared-my-cdk-popover.component.html',
    styleUrls: ['./shared-my-cdk-popover.component.scss']
})
export class SharedMyCdkPopoverComponent implements OnInit {
    renderMethod: 'template' | 'component' | 'text' = 'component';
    content: PopoverContent;
    context;
    focusTrapActive: boolean;
    constructor(private _popoverRef: MyCdkPopoverRef) {}

    ngOnInit() {
        this.content = this._popoverRef.content;
        this.focusTrapActive = this._popoverRef.focusTrapActive;

        if (typeof this.content === 'string') {
            this.renderMethod = 'text';
        } else if (this.content instanceof TemplateRef) {
            this.renderMethod = 'template';
            this.context = {
                close: this._popoverRef.close.bind(this._popoverRef)
            };
        }
    }
}
