import { Component, OnInit } from '@angular/core';
import { ToastService } from '@shared/services/toast.service';
import { Subscription } from 'rxjs';
import { IToastMessage, ToastType } from '@shared/models/IToastMessage';

@Component({
    selector: 'shared-toaster',
    templateUrl: './toaster.component.html',
    styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent implements OnInit {
    private _toastSubscription: Subscription;
    private _localToastQueue: IToastMessage[] = [];

    constructor(private _toastService: ToastService) {}

    ngOnInit() {
        this._toastSubscription = this._toastService.toastQueue$.subscribe(
            (toastMsg: IToastMessage) => {
                if (toastMsg && toastMsg.style !== ToastType.BLANK) {
                    this._localToastQueue = [
                        toastMsg
                        // ...this._localToastQueue Removed in EAF-136 due to excessive toast messages.
                        // Only one toast should show at a time.
                    ];
                    this._toastService.purgeToast();
                }
            }
        );
    }

    get availableToasts(): IToastMessage[] {
        return this._localToastQueue;
    }

    trackToasts(index, toast) {
        return toast ? toast.id : undefined;
    }

    expireToast(idToExpire: number) {
        this._localToastQueue = this._localToastQueue.filter(
            (toast: IToastMessage) => {
                return toast.id !== idToExpire;
            }
        );
        // this._toastService.purgeToast();
    }
}
