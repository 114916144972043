/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toaster.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../app-toast/app-toast.component.ngfactory";
import * as i3 from "../app-toast/app-toast.component";
import * as i4 from "@angular/common";
import * as i5 from "./toaster.component";
import * as i6 from "../../services/toast.service";
var styles_ToasterComponent = [i0.styles];
var RenderType_ToasterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToasterComponent, data: {} });
export { RenderType_ToasterComponent as RenderType_ToasterComponent };
function View_ToasterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-toast", [], null, [[null, "onExpire"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onExpire" === en)) {
        var pd_0 = (_co.expireToast($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AppToastComponent_0, i2.RenderType_AppToastComponent)), i1.ɵdid(2, 114688, null, 0, i3.AppToastComponent, [], { toastMsg: [0, "toastMsg"] }, { onExpire: "onExpire" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ToasterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToasterComponent_1)), i1.ɵdid(1, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.availableToasts; var currVal_1 = _co.trackToasts; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ToasterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-toaster", [], null, null, null, View_ToasterComponent_0, RenderType_ToasterComponent)), i1.ɵdid(1, 114688, null, 0, i5.ToasterComponent, [i6.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToasterComponentNgFactory = i1.ɵccf("shared-toaster", i5.ToasterComponent, View_ToasterComponent_Host_0, {}, {}, []);
export { ToasterComponentNgFactory as ToasterComponentNgFactory };
