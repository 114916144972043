import * as i0 from "@angular/core";
var SharedValidationService = /** @class */ (function () {
    function SharedValidationService() {
        this.messages = {
            required: 'Required',
            invalidEmailAddress: 'Please enter a valid email address.',
            email: 'Please enter a valid email address.',
            duplicateEmailAddress: 'This user has already been added to the institution.',
            startsWhitespace: 'Input cannot start with a space.',
            endsWhitespace: 'Input cannot end with a space.',
            emailRequired: 'Email is required.',
            passwordRequired: 'Password is required.',
            passwordMinLength: 'Password should contain at least 8 characters.',
            digitRequired: 'Password should contain at least one number.',
            lowerCaseRequired: 'Password should contain at least one lower case letter.',
            upperCaseRequired: 'Password should contain at least one upper case letter.',
            specialCharsRequired: 'Password should contain at least one special character.',
            passwordMismatch: 'Passwords must match.',
            invalidPassword: 'Invalid password',
            datesNotInOrder: 'Select an end date that is after the start date.',
            duplicateName: 'A strategy with the same name exists for this institution.',
            duplicateResourceLinkName: 'A link already exists with this name. Please enter a unique name.',
            invalidExternalLinkUrl: 'Invalid link.',
            externalLinkUrlRequired: 'Please add a web address, e.g. www.website.com.',
            attachmentFileNameRequired: 'Please add an attachment name.',
            attachmentDescriptionRequired: 'Please add a description.',
            attachmentFileSize: 'File size exceeds 10 MB limit.',
            attachmentFileType: 'The file is not valid. Please upload a different one.',
            noSpaces: 'Spaces are invalid characters.'
        };
    }
    SharedValidationService.prototype.getValidatorErrorMessage = function (key) {
        return this.messages[key] || key;
    };
    SharedValidationService.prototype.getMaxLengthErrorMessage = function (maxChars) {
        return "Max of " + maxChars + " characters";
    };
    SharedValidationService.prototype.getMinNumberErrorMessage = function (min) {
        return "Cannot be less than " + min;
    };
    SharedValidationService.prototype.matchingPasswords = function (passwordKey, passwordConfirmationKey) {
        return function (group) {
            var passwordInput = group.controls[passwordKey];
            var passwordConfirmationInput = group.controls[passwordConfirmationKey];
            if (passwordInput.value &&
                passwordConfirmationInput.value &&
                passwordInput.value !== passwordConfirmationInput.value) {
                return passwordConfirmationInput.setErrors({
                    passwordMismatch: true
                });
            }
        };
    };
    SharedValidationService.prototype.spaceChecker = function (control) {
        // Note: For inputs with type="email", the sharedTrimEmail directive
        // must be applied for cross-browser consistency.
        if (control.value) {
            if (control.value.charAt(0) === ' ') {
                return { startsWhitespace: true };
            }
            if (control.value.slice(-1) === ' ') {
                return { endsWhitespace: true };
            }
            return null;
        }
        else {
            return null;
        }
    };
    SharedValidationService.prototype.passwordValidator = function (control) {
        return !control.value ? { passwordRequired: true } : null;
    };
    SharedValidationService.prototype.emailValidator = function (control) {
        var invalidErr = { invalidEmailAddress: true };
        if (control.value) {
            if (control.value.length > 254) {
                // Total length cannot be more
                // than 254 octets due to opening angle bracket and closing angle
                // bracket defined in the Path in the spec.
                return invalidErr;
            }
            else {
                // split and check length of both halves.
                var split = control.value.split('@');
                if ((split[0] && split[0].length > 64) ||
                    (split[1] && split[1].length > 255)) {
                    return invalidErr;
                }
                else {
                    // run regular regex validation
                    // tslint:disable-next-line:max-line-length
                    var emailPattern = new RegExp(/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/);
                    var testResult = emailPattern.test(control.value)
                        ? null
                        : invalidErr;
                    return testResult;
                }
            }
        }
        else {
            return invalidErr;
        }
    };
    SharedValidationService.ngInjectableDef = i0.defineInjectable({ factory: function SharedValidationService_Factory() { return new SharedValidationService(); }, token: SharedValidationService, providedIn: "root" });
    return SharedValidationService;
}());
export { SharedValidationService };
