<!--<shared-noauth-header-smart></shared-noauth-header-smart>-->
<div class="auth-content">
  <div class="my-flex-container">
    <form [formGroup]="loginForm" (ngSubmit)="login()">
      <div id="invalid-creds-wrapper">
        <div class="error-message invalid-creds" *ngIf="loginFormErrMsg">
          <i class="fa fa-exclamation-triangle"></i>
          <span class="message-text">{{loginFormErrMsg}}</span>
        </div>
      </div>
      <label for="email-input">Email Address</label>
      <div class="input-wrapper">
        <input id="email-input" autocomplete="email" class="block" type="email" name="email"
               [formControl]="loginForm.controls.email"
               sharedTrimEmail
               required>
        <app-error-msg *ngIf="!loginForm.controls['email'].errors?.required ; else requiredEmailError"
                       [control]="loginForm.controls['email']"
                       [show]="controlInvalid(loginForm.controls['email'])">
        </app-error-msg>
        <ng-template #requiredEmailError>
          <div class="error-message" *ngIf="controlInvalid(loginForm.controls['email'])">
            <i class="fa fa-exclamation-triangle"></i>
            <span class="message-text">{{LOGIN_ERRORS.NO_EMAIL}}</span>
          </div>
        </ng-template>
      </div>
      <label for="password-input">Password</label>
      <div class="input-wrapper">
        <input id="password-input" autocomplete="current-password" class="block" type="password" name="password"
               formControlName="password" required>
        <app-error-msg
          *ngIf="!loginForm.controls['password'].errors?.required
         && !loginForm.controls['password'].errors?.passwordRequired;
          else invalidPassword;"
          [control]="loginForm.controls['password']"
          [show]="controlInvalid(loginForm.controls['password'])"
        ></app-error-msg>
        <ng-template #invalidPassword>
          <div class="error-message" *ngIf="controlInvalid(loginForm.controls['password'])">
            <i class="fa fa-exclamation-triangle"></i>
            <span class="message-text">{{LOGIN_ERRORS.NO_PASSWORD}}</span>
          </div>
        </ng-template>
      </div>
      <button class="btn" type="submit" [disabled]="submitting">Login</button>
      <a class="pull-left auth-link" [routerLink]="['./reset-password']">Request Password</a>
      <a class="pull-right auth-link" href="mailto:contactus@cognia.org?subject=Cognia%20assessments%20question">Contact
        us for login help</a>
    </form>
    <!--  For IE11 -->
    <div class="add-bottom-margin"></div>
  </div>
</div>

<p-dialog #tcDialog
          styleClass="assessments-tc-dialog"
          [visible]="showTCDialog"
          [modal]="true"
          [closable]="false"
          [draggable]="false">
  <p-header class="confirm-dialog-header-container">
    <h3 class="dialog-title">Terms & Conditions</h3>
  </p-header>
  <div><p class="confirm-bold instruction-text">Please review and agree to the current Terms and Conditions for the Cognia&trade; Improvement Platform</p></div>
  <div class="tc-check-container">
    <button sharedMyButton
            #tcCheckBox
            class="checkbox-wrapper"
            (click)="tcDialogChecked = !tcDialogChecked"
            (keydown)="wrapOnShiftTab($event)">
      <div class="checkbox-flex-container">
        <label [class.checked]="tcDialogChecked">
          <i *ngIf="tcDialogChecked" class="eleot-icon icon-check" aria-hidden="true"></i>
        </label>
      </div>
    </button>
        <p class="tc-text inline">I agree to the <a [href]="tcPath">Terms & Conditions</a>.
        </p>
  </div>
  <p-footer>
    <button type="button"
            #tcCancelButton
            (keydown)="!tcDialogChecked ? wrapOnTab($event): null"
            class="btn btn-alt"
            [disabled]="submitting"
            (click)="onCancelTC()">Cancel</button>
    <button type="button"
            #tcConfirmButton
            (keydown)="wrapOnTab($event)"
            [disabled]="!tcDialogChecked || submitting"
            class="btn confirm-button"
            (click)="onAcceptTC()">Continue</button>
  </p-footer>
</p-dialog>
