import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpParams,
    HttpUrlEncodingCodec
} from '@angular/common/http';
import { SharedConstantsService } from '../../../shared/services/shared-constants.service';
import {
    BehaviorSubject,
    Observable,
    ObservableInput,
    of,
    throwError
} from 'rxjs';
import { Institution } from '../../../shared/classes/institution';
import { User } from '../../../shared/classes/user';
import { SharedBaseService } from '../../../shared/services/shared-base.service';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { SharedMyMessageService } from '@shared/services/shared-my-message.service';
import { SharedInstitutionDataStore } from '@shared/services/shared-institution-data-store.service';
import { Customer } from '@shared/models/Customer';
import { environment } from '@environments/environment';
import { AuthUserService } from '@modules/auth/services/auth-user.service';
import { AuthService } from '@modules/auth/services/auth.service';
import { Router } from '@angular/router';
import { IModuleInfoInterface } from '@modules/institution/classes/server-dtos/IModuleInfo.interface';

@Injectable({
    providedIn: 'root'
})
export class InstitutionService extends SharedBaseService {
    private _institutionShortListBehaviorSubject: BehaviorSubject<
        Institution[]
    > = new BehaviorSubject<Institution[]>(undefined);
    institutionShortList$: Observable<
        Institution[]
    > = this._institutionShortListBehaviorSubject.asObservable();

    private institutionShortList: Institution[];
    selectedInstitution$: Observable<Institution>;

    userOnlyHasOneInstitution: boolean = false;

    constructor(
        private _Constants: SharedConstantsService,
        private _http: HttpClient,
        private _authService: AuthService,
        private _authUserService: AuthUserService,
        private __sharedMyMessageService: SharedMyMessageService,
        private _sharedInstitutionDataStore: SharedInstitutionDataStore,
        private _router: Router
    ) {
        super(_http, __sharedMyMessageService);
        this._institutionShortListBehaviorSubject.subscribe(
            (institutions: Institution[]) => {
                this.institutionShortList = institutions;
            }
        );
        this.selectedInstitution$ = this._sharedInstitutionDataStore.selectedInstitution$.pipe(
            catchError(err => {
                console.error(
                    'InstitutionService selectedInstitution$ error: ',
                    err
                );
                return of(err);
            })
        );

        this._authService.clearUserData$.subscribe(() => {
            this.clearInstitutionShortList();
            this.clearSelectedInstitution();
            this.userOnlyHasOneInstitution = false; // Resets to default of false
        });
    }

    clearInstitutionShortList() {
        this._institutionShortListBehaviorSubject.next(undefined);
    }

    /**
     * Get access information needed for showing the Go To Online Administration button
     */
    getFormativeAccessInfo = (
        customerId: number
    ): Observable<IModuleInfoInterface[]> => {
        return this._http.get<IModuleInfoInterface[]>(
            `${this.baseUrl}/customers/${customerId}${SharedConstantsService.ENDPOINTS.SUB_FORMATIVE_ACCESS_INFO}`
        );
    };

    /**
     * This method will fetch the institution information for any direct associations and their children
     */
    childrenWithChildStatus = (
        instIds: Array<number>
    ): Observable<Institution[]> => {
        const params = new HttpParams({
            encoder: new HttpUrlEncodingCodec()
        }).set('customerStatus', 'Active');
        return this._http
            .post<Institution[]>(
                `${this.baseUrl}${SharedConstantsService.ENDPOINTS.CUSTOMER_WITH_CHILD_STATUS}`,
                instIds,
                { params }
            )
            .pipe(
                tap((res: Institution[]) => {
                    this._institutionShortListBehaviorSubject.next(res);
                    this.institutionShortList = res;
                }),
                catchError(this.handleError)
            );
    };

    /**
     * Fetches children information of the given institution ids
     * @param {number[]} instIds
     * @returns {Observable<Institution[]>}
     */
    getChildrenWithChildStatus = (
        instIds: number[]
    ): Observable<Institution[]> => {
        const params = new HttpParams({
            encoder: new HttpUrlEncodingCodec()
        }).set('customerStatus', 'Active');
        return this._http
            .get<any>(
                `${this.baseUrl}/id/${instIds[0]}${SharedConstantsService.ENDPOINTS.SUB_CHILDREN_WITH_CHILD_STATUS}`,
                { params }
            )
            .pipe(
                map((res: Institution[]) => {
                    const payload: Institution[] = res;
                    const existingHierarchy: Institution[] = this
                        .institutionShortList;
                    existingHierarchy[0].children = payload;
                    this._institutionShortListBehaviorSubject.next(
                        existingHierarchy
                    );
                    return payload;
                    // TODO: Fix institution hierarchy merging
                }),
                catchError(this.handleError)
            );
    };

    searchForInstitution = (term: string): Observable<Institution[]> => {
        return this._authUserService.user$.pipe(
            take(1),
            switchMap((user: User) => {
                if (user) {
                    if (user.superUser) {
                        return this.searchForInstitutionAsSuperUser(term);
                    } else {
                        return this.searchForInstitutionAsNonSuperUser(
                            term,
                            user
                        );
                    }
                } else {
                    return throwError(
                        new Error('No user information available')
                    );
                }
            })
        );
    };

    searchForInstitutionAsSuperUser = (
        term: string
    ): Observable<Institution[]> => {
        const params = new HttpParams()
            .set('likeName', term)
            .set('size', '15')
            .set('from', '0')
            .set('predefinedOrder', 'true')
            .set('searchByCustomerNumber', 'true')
            .set('customerStatus', 'All');
        return this._http
            .get<Institution[]>(
                `${this.baseUrl}${SharedConstantsService.ENDPOINTS.INSTITUTION_SEARCH}?`,
                { params: params }
            )
            .pipe(
                catchError(
                    (err: Error): ObservableInput<any> => {
                        return throwError(err);
                    }
                )
            );
    };

    searchForInstitutionAsNonSuperUser = (
        term: string,
        user: User
    ): Observable<Institution[]> => {
        const request: {
            likeName: string;
            customerIds: number[];
            from: number;
            size: number;
            customerStatus: string;
            searchByCustomerNumber: boolean;
        } = {
            likeName: term,
            customerIds: user.associationIds,
            from: 0,
            size: 15,
            customerStatus: 'Active',
            searchByCustomerNumber: true
        };
        // TODO: There is an optional query param on this endpoint -  predefinedOrder: boolean
        // TODO: Determine if predefinedOrder param ever needs to be used.
        return this._http
            .post<Institution[]>(
                `${environment.customerUrl}/search/children`,
                request
            )
            .pipe(catchError(this.handleError));
    };

    getOrderedAncestors = (institutionId: number): Observable<number[]> => {
        return this._http
            .get<number[]>(
                `${environment.customerUrl}/customers/${institutionId}/orderedAncestors`
            )
            .pipe(catchError(this.handleError));
    };

    setSelectedInstitution = (institution: Institution): void => {
        this._sharedInstitutionDataStore.setSelectedInstitution(institution);
    };

    clearSelectedInstitution = (): void => {
        this._sharedInstitutionDataStore.setSelectedInstitution(undefined);
    };

    /**
     * TODO: Work with joe to figure out why the response object doesn't always contain address information. Discuss the potential for a new endpoint to just return the info we need.
     * TODO: also note I tried the POST /users endpoint to add a  user and always get back a 500 with the payload below
     * {
  "email": "aseditmde@yopmail.com",
  "firstName": "FName",
  "institutionId": 39893,
  "institutionName": "Michigan Department of Education (MDE)",
  "lastName": "LName",
  "newUser": true,
  "role": "admin",
  "sendEmailInvite": false
}
     * @param {number} institutionId
     * @returns {Observable<Customer>}
     */
    getInstitutionMetaData = (institutionId: number): Observable<any> => {
        return this._http
            .get<any>(
                `${this.baseUrl}${SharedConstantsService.ENDPOINTS.CUSTOMER_INFO}/${institutionId}`
            )
            .pipe(catchError(this.handleError));
    };

    checkIfUserHasMoreThanOneInstitution = (
        user: User
    ): Observable<boolean> => {
        if (user.associations && user.associations.length > 1) {
            this.userOnlyHasOneInstitution = false;
            return of(true);
        }
        // We must call childrenWithChildStatus to determine
        // if the user's institution has any child institutions
        // under it in the hierarchy. If there are child institutions
        // the user can navigate to the Select Institution page.
        return this.childrenWithChildStatus([user.associations[0].id]).pipe(
            map((institutionArray: [Institution]) => {
                // Normally this endpoint can
                // return more than one item in the response array,
                // however, since we're only checking one institution
                // to see if it has active children, this will always
                // be an array of one item.
                if (
                    institutionArray &&
                    institutionArray.length &&
                    institutionArray[0] &&
                    institutionArray[0].childCount
                ) {
                    return true;
                } else {
                    this.userOnlyHasOneInstitution = true;
                    return false;
                }
            }),
            catchError((err: HttpErrorResponse) => {
                console.error(err);
                this._authService.logoutOnClientOnly();
                return of(false);
            })
        );
    };
}
