import { Association } from '@shared/classes/association.class';

export class User {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    token: string;
    superUser: boolean;
    associations: Association[];
    associationIds: number[];
    currentTermsAndConditions: boolean;
    currentTermsAndConditionsPath: string;
    ssoUserId: string;

    constructor(
        data:
            | User
            | {
                  id: string;
                  firstName: string;
                  middleName: string;
                  lastName: string;
                  email: string;
                  token: string;
                  superUser: boolean;
                  associations: Association[];
                  associationIds: number[];
                  currentTermsAndConditions: boolean;
                  currentTermsAndConditionsPath: string;
                  ssoUserId: string;
              } = {
            id: '',
            firstName: '',
            middleName: '',
            lastName: '',
            email: '',
            token: '',
            superUser: false,
            associations: [],
            associationIds: [],
            currentTermsAndConditions: false,
            currentTermsAndConditionsPath: '',
            ssoUserId: ''
        }
    ) {
        this.id = data.id;
        this.firstName = data.firstName;
        this.middleName = data.middleName;
        this.lastName = data.lastName;
        this.email = data.email;
        this.token = data.token;
        this.superUser = data.superUser;
        this.associations = data.associations;
        this.associationIds = data.associationIds;
        this.currentTermsAndConditions = data.currentTermsAndConditions;
        this.currentTermsAndConditionsPath = data.currentTermsAndConditionsPath;
        this.ssoUserId = data.ssoUserId;
    }

    fullName = (): string => {
        return (
            this.firstName +
            ' ' +
            (this.middleName
                ? ',' + this.middleName + ',' + this.lastName
                : this.lastName)
        );
    };
}
