/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shared-noauth-header-presentation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared-noauth-header-presentation.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../modules/auth/services/auth.service";
import * as i5 from "../../../modules/auth/services/auth-user.service";
var styles_SharedNoauthHeaderPresentationComponent = [i0.styles];
var RenderType_SharedNoauthHeaderPresentationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SharedNoauthHeaderPresentationComponent, data: {} });
export { RenderType_SharedNoauthHeaderPresentationComponent as RenderType_SharedNoauthHeaderPresentationComponent };
export function View_SharedNoauthHeaderPresentationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "header", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "img-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "cognia logo"], ["src", "assets/images/full-cognia-logo-black.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/images/assessments-icon.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" assessments"]))], null, null); }
export function View_SharedNoauthHeaderPresentationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-noauth-header-presentation", [], null, null, null, View_SharedNoauthHeaderPresentationComponent_0, RenderType_SharedNoauthHeaderPresentationComponent)), i1.ɵdid(1, 638976, null, 0, i2.SharedNoauthHeaderPresentationComponent, [i3.Router, i4.AuthService, i5.AuthUserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SharedNoauthHeaderPresentationComponentNgFactory = i1.ɵccf("shared-noauth-header-presentation", i2.SharedNoauthHeaderPresentationComponent, View_SharedNoauthHeaderPresentationComponent_Host_0, { title: "title", showMenu: "showMenu", detailId: "detailId", user: "user" }, { goBackEmitter: "goBackEmitter" }, []);
export { SharedNoauthHeaderPresentationComponentNgFactory as SharedNoauthHeaderPresentationComponentNgFactory };
