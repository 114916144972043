import { Component, Input, OnInit } from '@angular/core';
import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';
import { SidenavService } from '@shared/services/sidenav.service';

@Component({
    selector: 'shared-aside-content',
    templateUrl: './shared-aside-content.component.html',
    styleUrls: ['./shared-aside-content.component.scss'],
    animations: [
        trigger('viewState', [
            state(
                'active',
                style({
                    transform: 'translateX(0px)'
                })
            ),
            state(
                'inactive',
                style({
                    transform: 'translateX(-{{width}})'
                }),
                { params: { width: '16.25rem' } }
            ),
            transition('active => inactive', animate('300ms ease-out')),
            transition('inactive => active', animate('300ms ease-in'))
        ])
    ]
})
export class SharedAsideContentComponent implements OnInit {
    @Input() open: boolean;
    @Input() width: string;

    constructor(private _sidenavService: SidenavService) {}

    ngOnInit() {}
}
