import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SharedValidationService } from '@shared/services/shared-validation.service';
var AppErrorMsgComponent = /** @class */ (function () {
    function AppErrorMsgComponent(validationService) {
        this.validationService = validationService;
    }
    Object.defineProperty(AppErrorMsgComponent.prototype, "hasWarnings", {
        get: function () {
            return this.control.errors && this.control.errors.warn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppErrorMsgComponent.prototype, "styleClass", {
        get: function () {
            if (!this.show) {
                return 'error-message';
            }
            else if (this.hasWarnings) {
                return 'warn-message';
            }
            else {
                return 'error-message';
            }
        },
        enumerable: true,
        configurable: true
    });
    AppErrorMsgComponent.prototype.ngOnInit = function () { };
    AppErrorMsgComponent.prototype.ngOnChanges = function (changes) {
        this.show = changes.show ? changes.show.currentValue : false;
    };
    Object.defineProperty(AppErrorMsgComponent.prototype, "errorMessage", {
        get: function () {
            for (var propertyName in this.control.errors) {
                if (this.control.errors.hasOwnProperty(propertyName) &&
                    this.control.errors[propertyName]) {
                    switch (propertyName) {
                        case 'maxlength':
                            return this.validationService.getMaxLengthErrorMessage(this.control.errors[propertyName].requiredLength);
                        case 'min':
                            return this.validationService.getMinNumberErrorMessage(this.control.errors[propertyName].min);
                        default:
                            return this.validationService.getValidatorErrorMessage(propertyName);
                    }
                }
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    return AppErrorMsgComponent;
}());
export { AppErrorMsgComponent };
