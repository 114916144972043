<div class="auth-content">
  <div class="my-flex-container">
    <h1>Create Password</h1>
    <form [formGroup]="passwordForm" (ngSubmit)="submitReset()">
      <div class="subtext centered-with-padding">
        Create a new password for your account:
      </div>
      <label for="password">Enter New Password</label>
      <div class="input-wrapper">
        <input
          id="password"
          [ngClass]="{'invalid': passwordForm.controls.password.dirty &&
        ( !validationsPassed || !passwordValidator.noSpaces || passwordForm.controls.password.invalid)}"
          [attr.aria-invalid]="passwordForm.controls.password.dirty &&
        ( !validationsPassed || !passwordValidator.noSpaces || passwordForm.controls.password.invalid)"
          class="block" type="password" name="password" autocomplete="new-password" formControlName="password">
        <div role="alert" aria-atomic="true"
             class="error-message"
             *ngIf="passwordForm.controls.password.dirty && !passwordValidator.noSpaces">
          <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
          <span class="message-text">Spaces are invalid characters.</span>
        </div>
      </div>
      <label for="confirm-password">Re-enter Password</label>
      <div class="input-wrapper">
        <input
          id="confirm-password"
          [ngClass]="{'invalid': passwordForm.controls.confirmpassword.touched && passwordForm.controls.confirmpassword.dirty
           && passwordForm.controls.confirmpassword.errors?.passwordMismatch}"
          [attr.aria-invalid]="passwordForm.controls.confirmpassword.touched && passwordForm.controls.confirmpassword.dirty
           && passwordForm.controls.confirmpassword.errors?.passwordMismatch" class="block" type="password"
          name="confirmpassword" autocomplete="new-password" formControlName="confirmpassword">
        <div role="alert" aria-atomic="true" class="error-message"
             *ngIf="passwordForm.controls.confirmpassword.touched
           && passwordForm.controls.confirmpassword.dirty
           && passwordForm.controls.confirmpassword.errors?.passwordMismatch">
          <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
          <span class="message-text">Passwords must match.</span>
        </div>
      </div>
      <div class="subtext">
        Password must meet the following requirements:
        <ul aria-live="polite" aria-atomic="true" class="password-rules">
          <li [ngClass]="{'error-msg-active': passwordForm.controls.password.dirty && !passwordValidator.eightChar }">
            <span *ngIf="!(passwordForm.controls.password.dirty && !passwordValidator.eightChar); else errorEightChar">Be at least 8 characters</span>
            <ng-template #errorEightChar>
              <span class="error-msg-active my-error">Be at least 8 characters<span>Invalid</span></span>
            </ng-template>
          </li>
          <li [ngClass]="{'error-msg-active': passwordForm.controls.password.dirty && !passwordValidator.oneNumber}">
          <span aria-relevant="additions"
                *ngIf="!(passwordForm.controls.password.dirty && !passwordValidator.oneNumber); else errorOneNumber">At least one number</span>
            <ng-template #errorOneNumber>
              <span class="error-msg-active my-error">At least one number<span>Invalid</span></span>
            </ng-template>
          </li>
          <li [ngClass]="{'error-msg-active': passwordForm.controls.password.dirty && !passwordValidator.oneLowercase}">
          <span aria-relevant="additions"
                *ngIf="!(passwordForm.controls.password.dirty && !passwordValidator.oneLowercase); else errorOneLowerCase">At least one lowercase letter</span>
            <ng-template #errorOneLowerCase>
              <span class="error-msg-active my-error">At least one lowercase letter<span>Invalid</span></span>
            </ng-template>
          </li>
          <li [ngClass]="{'error-msg-active': passwordForm.controls.password.dirty && !passwordValidator.oneUppercase}">
          <span aria-relevant="additions"
                *ngIf="!(passwordForm.controls.password.dirty && !passwordValidator.oneUppercase); else errorOneUpperCase">At least one uppercase letter</span>
            <ng-template #errorOneUpperCase>
              <span class="error-msg-active my-error">At least one uppercase letter<span>Invalid</span></span>
            </ng-template>
          </li>
          <li
            [ngClass]="{'error-msg-active': passwordForm.controls.password.dirty && !passwordValidator.oneSpecialChar}">
          <span aria-relevant="additions"
                *ngIf="!(passwordForm.controls.password.dirty && !passwordValidator.oneSpecialChar); else errorOneSpecialChar">At least one special character <span
            aria-hidden="true">!@#$%^&()-_=+~`.*</span></span>
            <ng-template #errorOneSpecialChar>
              <span class="error-msg-active my-error">At least one special character<span>Invalid</span></span><span
              aria-hidden="true"> !@#$%^&()-_=+~`.*</span>
            </ng-template>
          </li>
        </ul>
      </div>
      <button class="btn" type="submit" [disabled]="passwordForm.invalid || !validationsPassed || submitting">Submit
      </button>
      <!--    For IE11 -->
      <div class="add-bottom-margin"></div>
    </form>
  </div>
</div>
