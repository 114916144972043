import * as tslib_1 from "tslib";
import { HttpClient, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { SharedConstantsService } from '../../../shared/services/shared-constants.service';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { SharedBaseService } from '../../../shared/services/shared-base.service';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { SharedMyMessageService } from '@shared/services/shared-my-message.service';
import { SharedInstitutionDataStore } from '@shared/services/shared-institution-data-store.service';
import { environment } from '@environments/environment';
import { AuthUserService } from '@modules/auth/services/auth-user.service';
import { AuthService } from '@modules/auth/services/auth.service';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/services/shared-constants.service";
import * as i2 from "@angular/common/http";
import * as i3 from "../../auth/services/auth.service";
import * as i4 from "../../auth/services/auth-user.service";
import * as i5 from "../../../shared/services/shared-my-message.service";
import * as i6 from "../../../shared/services/shared-institution-data-store.service";
import * as i7 from "@angular/router";
var InstitutionService = /** @class */ (function (_super) {
    tslib_1.__extends(InstitutionService, _super);
    function InstitutionService(_Constants, _http, _authService, _authUserService, __sharedMyMessageService, _sharedInstitutionDataStore, _router) {
        var _this = _super.call(this, _http, __sharedMyMessageService) || this;
        _this._Constants = _Constants;
        _this._http = _http;
        _this._authService = _authService;
        _this._authUserService = _authUserService;
        _this.__sharedMyMessageService = __sharedMyMessageService;
        _this._sharedInstitutionDataStore = _sharedInstitutionDataStore;
        _this._router = _router;
        _this._institutionShortListBehaviorSubject = new BehaviorSubject(undefined);
        _this.institutionShortList$ = _this._institutionShortListBehaviorSubject.asObservable();
        _this.userOnlyHasOneInstitution = false;
        /**
         * Get access information needed for showing the Go To Online Administration button
         */
        _this.getFormativeAccessInfo = function (customerId) {
            return _this._http.get(_this.baseUrl + "/customers/" + customerId + SharedConstantsService.ENDPOINTS.SUB_FORMATIVE_ACCESS_INFO);
        };
        /**
         * This method will fetch the institution information for any direct associations and their children
         */
        _this.childrenWithChildStatus = function (instIds) {
            var params = new HttpParams({
                encoder: new HttpUrlEncodingCodec()
            }).set('customerStatus', 'Active');
            return _this._http
                .post("" + _this.baseUrl + SharedConstantsService.ENDPOINTS.CUSTOMER_WITH_CHILD_STATUS, instIds, { params: params })
                .pipe(tap(function (res) {
                _this._institutionShortListBehaviorSubject.next(res);
                _this.institutionShortList = res;
            }), catchError(_this.handleError));
        };
        /**
         * Fetches children information of the given institution ids
         * @param {number[]} instIds
         * @returns {Observable<Institution[]>}
         */
        _this.getChildrenWithChildStatus = function (instIds) {
            var params = new HttpParams({
                encoder: new HttpUrlEncodingCodec()
            }).set('customerStatus', 'Active');
            return _this._http
                .get(_this.baseUrl + "/id/" + instIds[0] + SharedConstantsService.ENDPOINTS.SUB_CHILDREN_WITH_CHILD_STATUS, { params: params })
                .pipe(map(function (res) {
                var payload = res;
                var existingHierarchy = _this
                    .institutionShortList;
                existingHierarchy[0].children = payload;
                _this._institutionShortListBehaviorSubject.next(existingHierarchy);
                return payload;
                // TODO: Fix institution hierarchy merging
            }), catchError(_this.handleError));
        };
        _this.searchForInstitution = function (term) {
            return _this._authUserService.user$.pipe(take(1), switchMap(function (user) {
                if (user) {
                    if (user.superUser) {
                        return _this.searchForInstitutionAsSuperUser(term);
                    }
                    else {
                        return _this.searchForInstitutionAsNonSuperUser(term, user);
                    }
                }
                else {
                    return throwError(new Error('No user information available'));
                }
            }));
        };
        _this.searchForInstitutionAsSuperUser = function (term) {
            var params = new HttpParams()
                .set('likeName', term)
                .set('size', '15')
                .set('from', '0')
                .set('predefinedOrder', 'true')
                .set('searchByCustomerNumber', 'true')
                .set('customerStatus', 'All');
            return _this._http
                .get("" + _this.baseUrl + SharedConstantsService.ENDPOINTS.INSTITUTION_SEARCH + "?", { params: params })
                .pipe(catchError(function (err) {
                return throwError(err);
            }));
        };
        _this.searchForInstitutionAsNonSuperUser = function (term, user) {
            var request = {
                likeName: term,
                customerIds: user.associationIds,
                from: 0,
                size: 15,
                customerStatus: 'Active',
                searchByCustomerNumber: true
            };
            // TODO: There is an optional query param on this endpoint -  predefinedOrder: boolean
            // TODO: Determine if predefinedOrder param ever needs to be used.
            return _this._http
                .post(environment.customerUrl + "/search/children", request)
                .pipe(catchError(_this.handleError));
        };
        _this.getOrderedAncestors = function (institutionId) {
            return _this._http
                .get(environment.customerUrl + "/customers/" + institutionId + "/orderedAncestors")
                .pipe(catchError(_this.handleError));
        };
        _this.setSelectedInstitution = function (institution) {
            _this._sharedInstitutionDataStore.setSelectedInstitution(institution);
        };
        _this.clearSelectedInstitution = function () {
            _this._sharedInstitutionDataStore.setSelectedInstitution(undefined);
        };
        /**
         * TODO: Work with joe to figure out why the response object doesn't always contain address information. Discuss the potential for a new endpoint to just return the info we need.
         * TODO: also note I tried the POST /users endpoint to add a  user and always get back a 500 with the payload below
         * {
      "email": "aseditmde@yopmail.com",
      "firstName": "FName",
      "institutionId": 39893,
      "institutionName": "Michigan Department of Education (MDE)",
      "lastName": "LName",
      "newUser": true,
      "role": "admin",
      "sendEmailInvite": false
    }
         * @param {number} institutionId
         * @returns {Observable<Customer>}
         */
        _this.getInstitutionMetaData = function (institutionId) {
            return _this._http
                .get("" + _this.baseUrl + SharedConstantsService.ENDPOINTS.CUSTOMER_INFO + "/" + institutionId)
                .pipe(catchError(_this.handleError));
        };
        _this.checkIfUserHasMoreThanOneInstitution = function (user) {
            if (user.associations && user.associations.length > 1) {
                _this.userOnlyHasOneInstitution = false;
                return of(true);
            }
            // We must call childrenWithChildStatus to determine
            // if the user's institution has any child institutions
            // under it in the hierarchy. If there are child institutions
            // the user can navigate to the Select Institution page.
            return _this.childrenWithChildStatus([user.associations[0].id]).pipe(map(function (institutionArray) {
                // Normally this endpoint can
                // return more than one item in the response array,
                // however, since we're only checking one institution
                // to see if it has active children, this will always
                // be an array of one item.
                if (institutionArray &&
                    institutionArray.length &&
                    institutionArray[0] &&
                    institutionArray[0].childCount) {
                    return true;
                }
                else {
                    _this.userOnlyHasOneInstitution = true;
                    return false;
                }
            }), catchError(function (err) {
                console.error(err);
                _this._authService.logoutOnClientOnly();
                return of(false);
            }));
        };
        _this._institutionShortListBehaviorSubject.subscribe(function (institutions) {
            _this.institutionShortList = institutions;
        });
        _this.selectedInstitution$ = _this._sharedInstitutionDataStore.selectedInstitution$.pipe(catchError(function (err) {
            console.error('InstitutionService selectedInstitution$ error: ', err);
            return of(err);
        }));
        _this._authService.clearUserData$.subscribe(function () {
            _this.clearInstitutionShortList();
            _this.clearSelectedInstitution();
            _this.userOnlyHasOneInstitution = false; // Resets to default of false
        });
        return _this;
    }
    InstitutionService.prototype.clearInstitutionShortList = function () {
        this._institutionShortListBehaviorSubject.next(undefined);
    };
    InstitutionService.ngInjectableDef = i0.defineInjectable({ factory: function InstitutionService_Factory() { return new InstitutionService(i0.inject(i1.SharedConstantsService), i0.inject(i2.HttpClient), i0.inject(i3.AuthService), i0.inject(i4.AuthUserService), i0.inject(i5.SharedMyMessageService), i0.inject(i6.SharedInstitutionDataStore), i0.inject(i7.Router)); }, token: InstitutionService, providedIn: "root" });
    return InstitutionService;
}(SharedBaseService));
export { InstitutionService };
