/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shared-main-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared-main-content.component";
var styles_SharedMainContentComponent = [i0.styles];
var RenderType_SharedMainContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SharedMainContentComponent, data: { "animation": [{ type: 7, name: "viewState", definitions: [{ type: 0, name: "squeezed", styles: { type: 6, styles: { width: "calc(100vw - {{asideWidth}})", transform: "translateX({{asideWidth}})" }, offset: null }, options: { params: { asideWidth: "16.25rem" } } }, { type: 0, name: "pushed", styles: { type: 6, styles: { width: "100%", transform: "translateX({{asideWidth}})" }, offset: null }, options: { params: { asideWidth: "16.25rem" } } }, { type: 0, name: "full", styles: { type: 6, styles: { width: "100%" }, offset: null }, options: undefined }, { type: 1, expr: "pushed => full", animation: { type: 4, styles: null, timings: "300ms ease-out" }, options: null }, { type: 1, expr: "full => pushed", animation: { type: 4, styles: null, timings: "300ms ease-in" }, options: null }, { type: 1, expr: "squeezed => full", animation: { type: 4, styles: null, timings: "300ms ease-out" }, options: null }, { type: 1, expr: "full => squeezed", animation: { type: 4, styles: null, timings: "300ms ease-in" }, options: null }], options: {} }] } });
export { RenderType_SharedMainContentComponent as RenderType_SharedMainContentComponent };
export function View_SharedMainContentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mainContentElementRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "main-content"]], [[24, "@viewState", 0]], null, null, null, null)), i1.ɵpod(2, { asideWidth: 0 }), i1.ɵpod(3, { value: 0, params: 1 }), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_co.showAside ? "pushed" : "full"), _ck(_v, 2, 0, _co.asideWidth)); _ck(_v, 1, 0, currVal_0); }); }
export function View_SharedMainContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-main-content", [], null, null, null, View_SharedMainContentComponent_0, RenderType_SharedMainContentComponent)), i1.ɵdid(1, 114688, null, 0, i2.SharedMainContentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SharedMainContentComponentNgFactory = i1.ɵccf("shared-main-content", i2.SharedMainContentComponent, View_SharedMainContentComponent_Host_0, { showAside: "showAside", asideWidth: "asideWidth", overlay: "overlay" }, { overlayClose: "overlayClose" }, ["*"]);
export { SharedMainContentComponentNgFactory as SharedMainContentComponentNgFactory };
