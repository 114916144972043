import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';

@Component({
    selector: 'shared-main-content',
    templateUrl: './shared-main-content.component.html',
    styleUrls: ['./shared-main-content.component.scss'],
    animations: [
        trigger('viewState', [
            state(
                'squeezed',
                style({
                    width: 'calc(100vw - {{asideWidth}})',
                    transform: 'translateX({{asideWidth}})'
                }),
                { params: { asideWidth: '16.25rem' } } // We must define a default value here. This is replaced by the input variable after init.
            ),
            state(
                'pushed',
                style({
                    width: '100%',
                    transform: 'translateX({{asideWidth}})'
                }),
                { params: { asideWidth: '16.25rem' } } // We must define a default value here. This is replaced by the input variable after init.
            ),
            state(
                'full',
                style({
                    width: '100%'
                })
            ),
            transition('pushed => full', animate('300ms ease-out')),
            transition('full => pushed', animate('300ms ease-in')),
            transition('squeezed => full', animate('300ms ease-out')),
            transition('full => squeezed', animate('300ms ease-in'))
        ])
    ]
})
export class SharedMainContentComponent implements OnInit {
    @ViewChild('mainContent') mainContentElementRef: ElementRef;
    @Output() overlayClose: EventEmitter<void> = new EventEmitter<void>();
    @Input() showAside: boolean;
    @Input() asideWidth: string;
    @Input() overlay: boolean = false;

    constructor() {}

    ngOnInit() {}
}
