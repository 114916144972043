import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
// import {ReportingDataStoreService} from '@modules/reporting/services/reporting-data-store.service';
import { User } from '@shared/classes/user';
import { AuthUserService } from '@modules/auth/services/auth-user.service';
import { InstitutionService } from '@modules/institution/services/institution.service';
import { Institution } from '@shared/classes/institution';
import { SharedInstitutionDataStore } from '@shared/services/shared-institution-data-store.service';

@Component({
    selector: 'shared-header-smart',
    templateUrl: './shared-header-smart.component.html',
    styleUrls: ['./shared-header-smart.component.scss']
})
export class SharedHeaderSmartComponent implements OnInit, OnDestroy {
    @Output() toggleLeftMenu = new EventEmitter<any>();

    private _institutionIdSubscription: Subscription;
    reportId: number;
    user$: Observable<User>;
    selectedInstitution: Institution;

    constructor(
        public router: Router,
        private _authUserService: AuthUserService,
        // private _reportingDataStoreService: ReportingDataStoreService,
        private _institutionService: InstitutionService,
        private _sharedInstitutionDataStore: SharedInstitutionDataStore,
        public location: Location
    ) {}

    // Lifecycle methods ====================================================

    ngOnInit() {
        this.user$ = this._authUserService.user$;
        // this._reportIdSubscription = this._reportingDataStoreService.reportId$.subscribe(
        //   (reportId: number) => {
        //     this.reportId = reportId;
        //   }
        // );
        this._institutionIdSubscription = this._sharedInstitutionDataStore.selectedInstitution$.subscribe(
            (institution: Institution) => {
                this.selectedInstitution = institution;
            },
            err => {
                console.error(
                    'SharedHeaderSmartComponent selectedInstitution$ error: ',
                    err
                );
            }
        );
    }

    ngOnDestroy() {
        // if (this._reportIdSubscription) {
        //   this._reportIdSubscription.unsubscribe();
        // }
        if (this._institutionIdSubscription) {
            this._institutionIdSubscription.unsubscribe();
        }
    }

    // All other methods ====================================================

    // checkForDetailId = (): number | null => {
    //   return this.reportId ? this.reportId : null;
    // }

    goBack = (): void => {
        this.location.back();
    };

    selectedInstitutionId = (): number => {
        return this.selectedInstitution ? this.selectedInstitution.id : null;
    };

    emitToggleLeftMenu = (): void => {
        this.toggleLeftMenu.emit(null);
    };
}
