import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SharedConstantsService {
    constructor() {}

    public static readonly ENDPOINTS = {
        LOGIN: '/login',
        LOGOUT: '/auth/logout',
        CUSTOMER_WITH_CHILD_STATUS: '/customersWithChildStatus',
        SUB_CHILDREN_WITH_CHILD_STATUS: '/childrenWithChildStatus',
        CONTENT_LIBRARY_TEMPLATES: '/templates',
        CUSTOMER_INFO: '/customer/id',
        FORGOT_PASSWORD: '/forgotpassword',
        FORGOT_PASSWORD_VALIDATE: '/forgotpasswordexpiry',
        INSTITUTION_SEARCH: '/search/superUserCustomers',
        RESET_PASSWORD: '/resetpassword',
        VALIDATE_PASSWORD: '/validatepassword',
        TERMS_AND_CONDITIONS: '/auth/accepttc',
        SUB_FORMATIVE_ACCESS_INFO: '/access-info/formative-assessments',
        USER_SSO_ACTIVITY: '/user/sso-activity'
    };
}
