/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-toast.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./app-toast.component";
var styles_AppToastComponent = [i0.styles];
var RenderType_AppToastComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppToastComponent, data: { "animation": [{ type: 7, name: "openClose", definitions: [{ type: 0, name: "open", styles: { type: 6, styles: { height: "80px", top: "0", opacity: 1, marginBottom: "20px" }, offset: null }, options: undefined }, { type: 0, name: "closed", styles: { type: 6, styles: { opacity: 0, height: "0px", top: "-100px", marginBottom: "0px" }, offset: null }, options: undefined }, { type: 1, expr: "open => closed", animation: [{ type: 4, styles: null, timings: "0.5s ease-out" }], options: null }, { type: 1, expr: "closed => open", animation: [{ type: 4, styles: null, timings: "0.5s ease-in" }], options: null }], options: {} }] } });
export { RenderType_AppToastComponent as RenderType_AppToastComponent };
function View_AppToastComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "toast-icon-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(2, "ui-messages-icon ", _co.iconRef(), " ", _co.iconStyle(), ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_AppToastComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], [[8, "className", 0], [24, "@openClose", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppToastComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "toast-message-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "toast-message-content-span"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "close-icon eleot-icon icon icon-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.hasIcon(); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "toast-wrapper ", _co.toastStyle(), ""); var currVal_1 = (_co.isOpen ? "open" : "closed"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.toastMsg.messageHtml; _ck(_v, 4, 0, currVal_3); }); }
export function View_AppToastComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toast", [], null, null, null, View_AppToastComponent_0, RenderType_AppToastComponent)), i1.ɵdid(1, 114688, null, 0, i3.AppToastComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppToastComponentNgFactory = i1.ɵccf("app-toast", i3.AppToastComponent, View_AppToastComponent_Host_0, { toastMsg: "toastMsg" }, { onExpire: "onExpire" }, []);
export { AppToastComponentNgFactory as AppToastComponentNgFactory };
