<div class="brand-bar">
  <!--<img src="assets/images/eprove-strategies-navbar-logo.svg" alt="eprove&trade; strategies">-->
</div>
<div class="left-nav-content">
  <div>
    <span class="logged-in-as">Logged in as</span>
    <button type="button" class="logout-button" (click)="onLogout()" (focus)="handleFocus($event)">Logout</button>
  </div>
  <div *ngIf="!!user" class="user-name">
    <span>{{user.firstName}} </span>
    <span>{{user.lastName}}</span>
  </div>
  <div *ngIf="!!selectedInstitution" class="strategy-info">
    <a *ngIf="!hasAccessToSingleInstitution; else canAccessMultipleInstitutions"
       [routerLink]="['/select-institution']"
       (click)="this.hide.emit();">
      <span class="institution-details">{{selectedInstitutionName()}}</span>
      <span *ngIf="hasLocationInfo()">{{selectedInstitutionCity()| titlecase}},</span>
      <span *ngIf="hasLocationInfo()">{{selectedInstitutionState() | titlecase}}</span>
    </a>
    <ng-template #canAccessMultipleInstitutions>
      <span class="institution-details">{{selectedInstitutionName()}}</span>
      <span *ngIf="hasLocationInfo()">{{selectedInstitutionCity()| titlecase}},</span>
      <span *ngIf="hasLocationInfo()">{{selectedInstitutionState() | titlecase}}</span>
    </ng-template>
  </div>

  <nav class="nav-menu">
    <ul id="nav-menu-ul">
      <li class="nav-item">
        <a [routerLink]="'/institution/' + selectedInstitution.id + '/formative-assessment-library'">
          <div class="link-label-container">
            <i class="icon icon-content-library"></i>
            <span class="link-text">Content Library</span>
          </div>
        </a>
      </li>
      <li class="nav-item" *ngIf="isUserAdminOrSuperAdmin">
        <a [routerLink]="['/institution/' + selectedInstitution.id + '/manage-users']">
          <div class="link-label-container">
            <i class="icon icon-manage-users"></i>
            <span class="link-text">Manage Users</span>
          </div>
        </a>
      </li>
    </ul>
  </nav>

  <div class="brand-bottom">
    <div class="row">
      <div class="side-logo">
        <img src="assets/images/full-cognia-logo-black.svg" alt="Cognia logo.">
      </div>
    </div>
    <div class="copyright-and-links-container">
      <p>© {{currentYear}} Cognia, Inc.</p>
      <a id="cognia-link" target="_blank" rel="noopener noreferrer" href="https://www.cognia.org/">cognia.org</a>
      <a target="_blank" rel="noopener noreferrer"
         href="https://www.cognia.org/user-terms/"
      >Terms and Conditions</a>
    </div>
  </div>
</div>
