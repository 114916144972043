import { Injectable } from '@angular/core';
import { User } from '@shared/classes/user';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthUserService {
    private readonly userKey = 'user';
    private _userSubject: BehaviorSubject<
        User | undefined
    > = new BehaviorSubject(undefined);
    user$: Observable<User | undefined> = this._userSubject.asObservable();
    private _user: User;
    constructor() {
        this.user$.subscribe((user: User) => {
            this._user = user;
        });
    }

    setUser(user: User) {
        this._userSubject.next(user);
        this.saveUser(user);
    }

    saveUser(user: User): void {
        try {
            localStorage.setItem(this.userKey, JSON.stringify(user));
        } catch (err) {
            console.error(`Caught error setting item: ${err.message}`);
        }
    }

    getDirectAssociations() {
        return this._user ? this._user.associations : null;
    }

    loadUser(): User {
        try {
            const savedUser: User = JSON.parse(
                localStorage.getItem(this.userKey)
            );
            this._userSubject.next(savedUser);
            return savedUser;
        } catch (err) {
            console.error(`Caught error setting item: ${err.message}`);
            return undefined;
        }
    }

    clearUser = (): void => {
        localStorage.removeItem(this.userKey);
        this._userSubject.next(undefined);
    };
}
