import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { AuthService } from '@modules/auth/services/auth.service';
import { Location } from '@angular/common';
import { ToastService } from '@shared/services/toast.service';
import { environment } from '@environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../../../shared/services/toast.service";
var CreatePasswordGuard = /** @class */ (function () {
    function CreatePasswordGuard(_authService, _location, _router, _toastService) {
        var _this = this;
        this._authService = _authService;
        this._location = _location;
        this._router = _router;
        this._toastService = _toastService;
        this._fullResetUrl = '';
        this.canActivate = function (next, state) {
            var url = window.location.href; // Note: use of window.location.href
            // tightly couples our app to a browser environment. This may affect
            // server-side rendering if it is ever implemented, in which case this
            // will have to be refactored.
            var path = _this._location.path();
            if (_this.fullResetUrl !== url &&
                path !== '' // We do not reset the url if the path is
            // empty, because this prevents a false reset to '' and/or the app root URL.
            ) {
                _this.fullResetUrl = url;
            }
            return _this._authService.resetPasswordToken$.pipe(take(1), // The Guard subscribes and retrieves the
            // BehaviorSubject's value exposed in this observable, then
            // unsubscribes after the first take. Otherwise, this piped logic
            // fires again as soon as this._authService.saveResetToken is called
            // below, which creates an extra call to the validation API.
            switchMap(function (storedToken) {
                var token = storedToken;
                if (token) {
                    return _this._authService.validateForgotPasswordLink("" + _this.fullResetUrl);
                    // Validation failure will be handled in the catchError at the end of the chain.
                }
                else if (next.paramMap.get('token')) {
                    // http://localhost:4200/login/confirm-reset/1
                    // Extract token, save it to service layer, reload page without token in url
                    var routeToken = next.paramMap.get('token');
                    _this._authService.saveResetToken(routeToken);
                    _this._router.navigate(['/login/confirm-reset']);
                    return of(false);
                }
                else {
                    // // token was never provided, redirect to login
                    _this._router.navigate(['/login']);
                    return of(false);
                }
            }), map(function (result) {
                if (result !== false) {
                    // Link is valid, allow user to update password
                    return true;
                }
                else {
                    return false;
                }
            }), catchError(function (err) {
                // Link is invalid or call failed, either way display error toast and redirect to login
                window.location.href =
                    environment.myJourneyUrl + '/expired-link';
                return of(false);
            }));
        };
    }
    Object.defineProperty(CreatePasswordGuard.prototype, "fullResetUrl", {
        get: function () {
            return this._fullResetUrl;
        },
        set: function (val) {
            this._fullResetUrl = val;
        },
        enumerable: true,
        configurable: true
    });
    CreatePasswordGuard.ngInjectableDef = i0.defineInjectable({ factory: function CreatePasswordGuard_Factory() { return new CreatePasswordGuard(i0.inject(i1.AuthService), i0.inject(i2.Location), i0.inject(i3.Router), i0.inject(i4.ToastService)); }, token: CreatePasswordGuard, providedIn: "root" });
    return CreatePasswordGuard;
}());
export { CreatePasswordGuard };
