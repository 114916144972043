import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[sharedMyButton]'
})
export class SharedMyButtonDirective {
    // This directive can be attached to any button element and used to
    // select that element via ViewChild, ViewChildren, ContentChild, or
    // ContentChildren

    elementRef: ElementRef;
    constructor(el: ElementRef) {
        this.elementRef = el;
    }
}
