import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ObservableInput, of, throwError } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { SharedMyMessageService } from '@shared/services/shared-my-message.service';

@Injectable({
    providedIn: 'root'
})
export class SharedBaseService {
    protected baseUrl: string = environment.baseUrl;
    protected customerApiUrl: string = environment.customerUrl;
    // protected mockUrl: string = environment.mockUrl;
    constructor(
        protected http: HttpClient,
        protected _sharedMyMessageService: SharedMyMessageService
    ) {}

    protected handleError = (err: Error): ObservableInput<any> => {
        this._sharedMyMessageService.showError({ detail: err.message });
        return throwError(err);
    };
}
