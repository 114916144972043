import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthService } from '@modules/auth/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class SidenavService {
    private _showAsideBehaviorSubject: BehaviorSubject<
        boolean
    > = new BehaviorSubject<boolean>(false);
    private _focusSkipToMainAnchorSubject: Subject<void> = new Subject<void>();
    focusSkipToMainAnchor$: Observable<
        void
    > = this._focusSkipToMainAnchorSubject.asObservable();
    private _focusExpandSidenavButtonSubject: Subject<void> = new Subject<
        void
    >();
    focusExpandSidenavButton$: Observable<
        void
    > = this._focusExpandSidenavButtonSubject.asObservable();
    showAside$: Observable<
        boolean
    > = this._showAsideBehaviorSubject.asObservable();
    constructor(private _authService: AuthService) {
        this._authService.clearUserData$.subscribe(() => {
            this.setShowAside(false);
        });
    }

    setShowAside = (flag: boolean) => {
        this._showAsideBehaviorSubject.next(flag);
    };

    focusExpandSidenavButton = () => {
        this._focusExpandSidenavButtonSubject.next();
    };

    focusSkipToMainAnchor = () => {
        this._focusSkipToMainAnchorSubject.next();
    };
}
