import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { TemplateRef, Type } from '@angular/core';

export interface PopoverCloseEvent<T> {
    type: 'backdropClick' | 'close';
    data: T;
}

export type PopoverContent = TemplateRef<any> | Type<any> | string;

export class MyCdkPopoverRef<T = any> {
    private afterClosedSubject = new Subject<PopoverCloseEvent<T>>();
    afterClosed$ = this.afterClosedSubject.asObservable();
    focusTrapActive: boolean;

    constructor(
        public content: PopoverContent,
        public data: T,
        public overlay: OverlayRef
    ) {
        this.focusTrapActive = true;
        overlay
            .backdropClick()
            .subscribe(() => this._close('backdropClick', data));
    }

    close(data?: T) {
        this._close('close', data);
    }

    private _close(type, data) {
        this.focusTrapActive = false;
        this.overlay.dispose();
        this.afterClosedSubject.next({
            type,
            data
        });
        this.afterClosedSubject.complete();
    }
}
