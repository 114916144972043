import { Injectable, Pipe } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '@modules/auth/services/auth.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
    constructor(private _authService: AuthService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const token: string = this._authService.getToken();

        if (
            token &&
            req.url.indexOf('login') < 0 &&
            req.url.indexOf('amazonaws.com') < 0 // AWS only allows the query param
            // token OR an Authorization header to be used. We stick with the query
            // params token.
        ) {
            req = req.clone({
                headers: req.headers.set('Authorization', token)
            });
        }

        if (
            !req.headers.has('Content-Type') &&
            req.url.indexOf('bulkUsers') < 0
        ) {
            req = req.clone({
                headers: req.headers.set('Content-Type', 'application/json')
            });
        }

        return next.handle(req).pipe(
            map(event => {
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (
                    error.status &&
                    (error.status === 401 || error.status === 0)
                ) {
                    this._authService.logoutOnClientOnly();
                }
                return throwError(error);
            })
        );
    }
}
