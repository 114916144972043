import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    Router,
    Event,
    ActivatedRouteSnapshot,
    NavigationEnd
} from '@angular/router';
import { filter, take, takeUntil } from 'rxjs/operators';
import { AuthService } from '@modules/auth/services/auth.service';
import { AuthUserService } from '@modules/auth/services/auth-user.service';
import { Observable, Subject } from 'rxjs';
import { MessageService } from 'primeng/api';
import { SharedMyMessageService } from '@shared/services/shared-my-message.service';

declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [],
    providers: [MessageService]
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'eprove-formative-assessments-app';
    private _destroySubject: Subject<undefined> = new Subject();
    private _destroy$: Observable<
        undefined
    > = this._destroySubject.asObservable();
    constructor(
        public router: Router,
        private _authService: AuthService,
        private _authUserService: AuthUserService,
        private _messageService: MessageService,
        private _sharedMyMessageService: SharedMyMessageService
    ) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                gtag('config', 'UA-3266229-14', {
                    page_path: event.urlAfterRedirects
                });
            }
        });
    }

    ngOnInit() {
        if (this._authService.isAuthorized()) {
            this._authUserService.user$
                .pipe(
                    take(1),
                    takeUntil(this._destroy$)
                )
                .subscribe(currentUser => {
                    if (!currentUser) {
                        this._authUserService.loadUser();
                    }
                });
        }

        // this.router.events.pipe(
        //   filter((event: Event): boolean => event instanceof NavigationEnd)
        // ).subscribe((event: NavigationEnd) => {  // We listen to all router navigation events to update IDs and other data in our services that our presentation-components will consume.
        //     let snapshot: ActivatedRouteSnapshot = this.router.routerState.root.snapshot;
        //     let reportId: number = 0;
        //     while (snapshot.firstChild) {
        //       snapshot = snapshot.firstChild;
        //       if (+snapshot.paramMap.get('reportId')) {
        //         reportId = +snapshot.paramMap.get('reportId');
        //       }
        //     }
        //
        //     if (reportId) {
        //       this._reportingDataStoreService.reportId = reportId;
        //     } else {
        //       this._reportingDataStoreService.reportId = 0;
        //     }
        //   }
        // );

        this._sharedMyMessageService.showSuccess$.subscribe(
            (event: {
                severity: string;
                detail: string;
                summary?: string;
                key?: string;
            }) => {
                this.showSuccess(event);
            }
        );
        this._sharedMyMessageService.showInfo$.subscribe(
            (event: {
                severity: string;
                detail: string;
                summary?: string;
                key?: string;
            }) => {
                this.showInfo(event);
            }
        );
        this._sharedMyMessageService.showWarn$.subscribe(
            (event: {
                severity: string;
                detail: string;
                summary?: string;
                key?: string;
            }) => {
                this.showWarn(event);
            }
        );
        this._sharedMyMessageService.showError$.subscribe(
            (event: {
                severity: string;
                detail: string;
                summary?: string;
                key?: string;
            }) => {
                this.showError(event);
            }
        );
        this._sharedMyMessageService.showCustom$.subscribe(
            (event: {
                severity: string;
                detail: string;
                summary?: string;
                key?: string;
            }) => {
                this.showCustom(event);
            }
        );
    }

    ngOnDestroy() {
        this._destroySubject.next(undefined);
        this._destroySubject.complete(); // We push next and then complete to unsubscribe to the user$ observable if take has not done so already.
    }

    showSuccess(
        content: { summary?: string; detail: string } = {
            summary: 'Success',
            detail: ''
        }
    ) {
        this._messageService.add({
            severity: 'success',
            summary: content.summary || 'Success',
            detail: content.detail
        });
    }

    showInfo(
        content: { summary?: string; detail: string } = {
            summary: 'Info',
            detail: ''
        }
    ) {
        this._messageService.add({
            severity: 'info',
            summary: content.summary || 'Info',
            detail: content.detail
        });
    }

    showWarn(
        content: { summary?: string; detail: string } = {
            summary: 'Warning',
            detail: ''
        }
    ) {
        this._messageService.add({
            severity: 'warn',
            summary: content.summary || 'Warning',
            detail: content.detail
        });
    }

    showError(
        content: { summary?: string; detail: string } = {
            summary: 'Error',
            detail: ''
        }
    ) {
        this._messageService.add({
            severity: 'error',
            summary: content.summary || 'Error',
            detail: content.detail
        });
    }

    showCustom(
        content: { summary?: string; detail: string } = {
            summary: 'Info',
            detail: ''
        }
    ) {
        this._messageService.add({
            key: 'custom',
            severity: 'info',
            summary: content.summary || 'Info',
            detail: content.detail
        });
    }
}
