<div class="menu-container">
  <ng-content></ng-content>
  <ul *ngIf="showMenu" role="menu" (blur)="handleBlur($event)">
    <li *ngFor="let option of menuOptions; index as i">
      <button tabindex="-1" sharedMyButton type="button" role="menuitem"
              (keydown)="resetMenuFocus($event, i, true, option.command)"
              [attr.aria-disabled]="option.disabled"
              [disabled]="option.disabled"
              (click)="toggleShowMenu($event, {command: option.command})"><i *ngIf="option.icon" aria-hidden="true"
        [ngClass]="option.icon"></i><span>{{option.label}}</span><i *ngIf="option.showOpenInNewViewIcon" class="icon icon-new-window"></i></button>
    </li>
  </ul>
</div>
