import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var SharedMenuService = /** @class */ (function () {
    function SharedMenuService() {
        var _this = this;
        this._closeAllMenusSubject = new Subject();
        this.closeAllMenus$ = this._closeAllMenusSubject.asObservable();
        this.closeAllMenus = function () {
            _this._closeAllMenusSubject.next();
        };
    }
    SharedMenuService.ngInjectableDef = i0.defineInjectable({ factory: function SharedMenuService_Factory() { return new SharedMenuService(); }, token: SharedMenuService, providedIn: "root" });
    return SharedMenuService;
}());
export { SharedMenuService };
