import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IToastMessage, ToastType } from '@shared/models/IToastMessage';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    private _toastQueueSubject: BehaviorSubject<
        IToastMessage | undefined
    > = new BehaviorSubject<IToastMessage>(undefined);
    private _toastQueueObservable$: Observable<
        IToastMessage | undefined
    > = this._toastQueueSubject.asObservable();
    private _runningToastIndex = 0;
    private _defaultToastDuration = 8000; // in ms
    private _defaultToastIcon = 'check';

    constructor() {}

    queueToast = (toastMsg: IToastMessage) => {
        if (toastMsg) {
            toastMsg.id = this._runningToastIndex;
            this._toastQueueSubject.next(toastMsg);
            this._runningToastIndex++; // increment index so next toast uses new id
        } else {
            console.error(
                `Tried to submit an undefined toast object to the toast queue`
            );
        }
    };

    queueDefaultToastWithMessage = (msg: string) => {
        if (msg) {
            const toast: IToastMessage = {
                duration: this._defaultToastDuration,
                iconRef: this._defaultToastIcon,
                messageHtml: msg,
                style: ToastType.NORMAL
            };
            this.queueToast(toast);
        } else {
            console.error(
                `Tried to submit a default toast with no message to the toast queue`
            );
        }
    };

    queueErrorToastWithMessage = (
        msg: string = `Action was unsuccessful, please try again.`
    ) => {
        if (msg) {
            const toast: IToastMessage = {
                duration: this._defaultToastDuration,
                iconRef: this._defaultToastIcon,
                messageHtml: msg,
                style: ToastType.ERROR
            };
            this.queueToast(toast);
        } else {
            console.error(
                `Tried to submit an error toast with no message to the toast queue`
            );
        }
    };

    queueFatalToastWithMessage = (msg: string) => {
        if (msg) {
            const toast: IToastMessage = {
                duration: this._defaultToastDuration,
                iconRef: this._defaultToastIcon,
                messageHtml: msg,
                style: ToastType.FATAL
            };
            this.queueToast(toast);
        } else {
            console.error(
                `Tried to submit an error toast with no message to the toast queue`
            );
        }
    };

    purgeToast() {
        this._toastQueueSubject.next({
            style: ToastType.BLANK,
            duration: 0.1,
            id: -1,
            iconRef: '',
            messageHtml: ''
        });
    }

    get toastQueue$() {
        return this._toastQueueObservable$;
    }
}
