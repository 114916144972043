import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';
import { AuthService } from '@modules/auth/services/auth.service';
import { AuthUserService } from '@modules/auth/services/auth-user.service';
import { User } from '@shared/classes/user';
import { MenuItem } from 'primeng/api';
import { SharedInstitutionDataStore } from '@shared/services/shared-institution-data-store.service';
import { Institution } from '@shared/classes/institution';
import { take, takeUntil } from 'rxjs/operators';
import { SidenavService } from '@shared/services/sidenav.service';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
    selector: 'shared-header-presentation',
    templateUrl: './shared-header-presentation.component.html',
    styleUrls: ['./shared-header-presentation.component.scss']
})
export class SharedHeaderPresentationComponent implements OnInit, OnDestroy {
    @Input() title: string;
    @Input() showMenu: boolean;
    @Input() detailId: number;
    @Input() institutionId: number;
    @Output() goBackEmitter: EventEmitter<null> = new EventEmitter();
    @Output() toggleLeftMenu: EventEmitter<null> = new EventEmitter();
    @ViewChild('expandSidenavButton') expandSidenavButton: ElementRef;

    @Input() user: User;
    items: [MenuItem];

    private _selectedInstitutionId: number;
    private _destroySubject: Subject<void> = new Subject<void>();
    private _destroy$: Observable<void> = this._destroySubject.asObservable();

    constructor(
        private _authService: AuthService,
        private _authUserService: AuthUserService,
        private _sharedInstitutionDataStore: SharedInstitutionDataStore,
        private _sidenavService: SidenavService,
        private _renderer2: Renderer2
    ) {}

    ngOnInit() {
        this.items = [
            {
                icon: 'fa fa-sign-out',
                label: 'Logout',
                command: this.logout.bind(this)
            }
        ];
        this._sharedInstitutionDataStore.selectedInstitution$
            .pipe(takeUntil(this._destroy$))
            .subscribe((institution: Institution) => {
                this._selectedInstitutionId = !!institution
                    ? institution.id
                    : null;
            });

        this._sidenavService.focusExpandSidenavButton$
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => {
                this._focusElement(this.expandSidenavButton);
            });
    }

    ngOnDestroy(): void {
        this._destroySubject.next();
        this._destroySubject.complete();
    }

    emitToggleLeftMenu = (): void => {
        this.toggleLeftMenu.emit(null);
    };

    hasSelectedInstitution = (): boolean => {
        return !!this._selectedInstitutionId;
    };

    logout = (): void => {
        this._authService.logout();
    };

    handleBlur = (event: KeyboardEvent) => {
        if (event.key === 'Tab' && !event.shiftKey) {
            this._sidenavService.showAside$
                .pipe(
                    take(1),
                    takeUntil(this._destroy$)
                )
                .subscribe((flag: boolean) => {
                    if (flag) {
                        // If the sidenav is open and we are tabbing away
                        // from the burger menu, close the sidenav.
                        this.emitToggleLeftMenu();
                    }
                });
        } else if (event.key === 'Tab' && event.shiftKey) {
            this._sidenavService.showAside$
                .pipe(
                    take(1),
                    takeUntil(this._destroy$)
                )
                .subscribe((flag: boolean) => {
                    if (!flag) {
                        // If the sidenav is closed and we are tabbing back
                        // from the expand sidenav button, skip all buttons
                        // and links in the sidenav and go to the skip to main
                        // content anchor.
                        this._sidenavService.focusSkipToMainAnchor();
                    }
                });
        }
    };

    private _focusElement = (elementRef: ElementRef) => {
        this._renderer2.setAttribute(
            elementRef.nativeElement,
            'tabindex',
            '-1'
        );
        setTimeout(() => {
            elementRef.nativeElement.focus();
            this._renderer2.removeAttribute(
                elementRef.nativeElement,
                'tabindex'
            );
        }, 0);
    };
}
