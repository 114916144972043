import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
} from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '@modules/auth/services/auth.service';
import { Location } from '@angular/common';
import { ToastService } from '@shared/services/toast.service';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CreatePasswordGuard implements CanActivate {
    constructor(
        private _authService: AuthService,
        private _location: Location,
        private _router: Router,
        private _toastService: ToastService
    ) {}
    private _fullResetUrl: string = '';
    get fullResetUrl(): string {
        return this._fullResetUrl;
    }
    set fullResetUrl(val: string) {
        this._fullResetUrl = val;
    }
    canActivate = (
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean => {
        const url = window.location.href; // Note: use of window.location.href
        // tightly couples our app to a browser environment. This may affect
        // server-side rendering if it is ever implemented, in which case this
        // will have to be refactored.
        const path = this._location.path();
        if (
            this.fullResetUrl !== url &&
            path !== '' // We do not reset the url if the path is
            // empty, because this prevents a false reset to '' and/or the app root URL.
        ) {
            this.fullResetUrl = url;
        }
        return this._authService.resetPasswordToken$.pipe(
            take(1), // The Guard subscribes and retrieves the
            // BehaviorSubject's value exposed in this observable, then
            // unsubscribes after the first take. Otherwise, this piped logic
            // fires again as soon as this._authService.saveResetToken is called
            // below, which creates an extra call to the validation API.
            switchMap(
                (storedToken: string): Observable<any> => {
                    const token = storedToken;
                    if (token) {
                        return this._authService.validateForgotPasswordLink(
                            `${this.fullResetUrl}`
                        );
                        // Validation failure will be handled in the catchError at the end of the chain.
                    } else if (next.paramMap.get('token')) {
                        // http://localhost:4200/login/confirm-reset/1
                        // Extract token, save it to service layer, reload page without token in url
                        const routeToken = next.paramMap.get('token');
                        this._authService.saveResetToken(routeToken);
                        this._router.navigate(['/login/confirm-reset']);
                        return of(false);
                    } else {
                        // // token was never provided, redirect to login
                        this._router.navigate(['/login']);
                        return of(false);
                    }
                }
            ),
            map(result => {
                if (result !== false) {
                    // Link is valid, allow user to update password
                    return true;
                } else {
                    return false;
                }
            }),
            catchError(err => {
                // Link is invalid or call failed, either way display error toast and redirect to login
                window.location.href =
                    environment.myJourneyUrl + '/expired-link';
                return of(false);
            })
        );
    };
}
