import {
    Component,
    OnInit,
    OnDestroy,
    ElementRef,
    ViewChild,
    Renderer2
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { environment } from '@environments/environment';
import { SharedValidationService } from '@shared/services/shared-validation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { AuthUserService } from '@modules/auth/services/auth-user.service';
import { Subscription } from 'rxjs';
import { User } from '@shared/classes/user';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'auth-login',
    templateUrl: './auth-login.component.html',
    styleUrls: ['./auth-login.component.scss']
})
export class AuthLoginComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;
    loginFormEmailErrMsg: string;
    loginFormPasswordErrMsg: string;
    loginFormErrMsg: string;
    userLoginSubscription: Subscription;
    submitted = false;
    submitting = false;
    showTCDialog = false;
    tcPath = 'https://www.cognia.org/user-terms/';
    tcDialogChecked: boolean;

    @ViewChild('tcCheckBox') tcCheckBox: ElementRef;
    @ViewChild('tcCancelButton') tcCancelBtn: ElementRef;
    @ViewChild('tcConfirmButton') tcConfirmBtn: ElementRef;

    readonly LOGIN_ERRORS = {
        INVALID_CREDENTIALS: 'Please enter a valid email address and password.',
        NO_ASSOCIATED_INSTITUTIONS:
            'User profile has no institution associated.',
        NO_EMAIL: 'Email is required.',
        NO_PASSWORD: 'Password is required.',
        DEFAULT: 'Please enter a valid email address and password.',
        NO_TC: 'You must accept the Terms & Conditions to login'
    };

    constructor(
        private _renderer2: Renderer2,
        private _route: ActivatedRoute,
        private _router: Router,
        private _formBuilder: FormBuilder,
        private _validationService: SharedValidationService,
        private _authService: AuthService,
        private _authUserService: AuthUserService
    ) {}

    ngOnInit() {
        this.submitting = false;
        this._authService.logoutOnClientOnly(); // Ensures that any user who goes to
        // the login screen is automatically logged out.
        this.loginForm = this._formBuilder.group({
            email: [
                '',
                [
                    this._validationService.emailValidator,
                    this._validationService.spaceChecker
                ]
            ],
            password: [
                '',
                [
                    this._validationService.passwordValidator,
                    this._validationService.spaceChecker
                ]
            ]
        });
    }

    ngOnDestroy() {
        if (this.userLoginSubscription) {
            this.userLoginSubscription.unsubscribe();
        }
        this.submitting = false;
    }

    controlInvalid = (
        formControl: AbstractControl,
        showErrorIfPristine: boolean = false
    ) => {
        return (
            ((formControl.touched &&
                (showErrorIfPristine ? true : formControl.dirty)) ||
                this.submitted) &&
            formControl.invalid
        );
    };

    login(): void {
        this.submitting = true;
        this.submitted = true;
        if (this.loginForm.valid) {
            if (!this.userLoginSubscription) {
                this.userLoginSubscription = this._authUserService.user$.subscribe(
                    (user: User) => {
                        if (user) {
                            if (!user.currentTermsAndConditions) {
                                this.tcDialogChecked = false;
                                this.showTCDialog = true;
                                return;
                            } else {
                                this._router.navigate([`select-institution`]);
                            }
                        }
                        // Treat a blank success response object as a failed login.
                        // Nothing happens.
                    }
                );
            }
            this.loginFormErrMsg = null;
            this.loginFormEmailErrMsg = null;
            this.loginFormPasswordErrMsg = null;
            this._authService
                .login(this.loginForm.value)
                .pipe(
                    finalize(() => {
                        this.submitting = false;
                    })
                )
                .subscribe(
                    res => {
                        this.loginFormErrMsg = null;
                    },
                    err => {
                        if (err.error.errorCode === 'INVALID_CREDENTIALS') {
                            this.loginFormErrMsg = this.LOGIN_ERRORS.INVALID_CREDENTIALS;
                        } else {
                            // TODO: See if we can update this message, this error case is actually for failed connections
                            this.loginFormErrMsg = this.LOGIN_ERRORS.DEFAULT;
                        }
                    }
                );
        } else {
            this.submitting = false;
            // Note we are using separate if cases instead of if/else cases so we can stack errors if needed.
            if (
                this.loginForm.controls.email.touched &&
                this.loginForm.controls.email.invalid
            ) {
                this.loginFormEmailErrMsg = this.LOGIN_ERRORS.NO_EMAIL;
            }
            if (
                this.loginForm.controls.password.touched &&
                this.loginForm.controls.password.invalid
            ) {
                this.loginFormPasswordErrMsg = this.LOGIN_ERRORS.NO_PASSWORD;
            }
        }
    }

    onCancelTC() {
        this.loginFormErrMsg = this.LOGIN_ERRORS.NO_TC;
        this.showTCDialog = false;
    }

    onAcceptTC() {
        this.submitting = true;
        this._authService.acceptTerms().subscribe(
            (res: any) => {
                this._router.navigate([`select-institution`]);
            },
            err => {
                this.submitting = false;
                console.log(`Error accepting terms and conditions: `, err);
            }
        );
    }

    wrapOnTab = (event: KeyboardEvent) => {
        if (event.key === 'Tab' && !event.shiftKey) {
            event.preventDefault();
            event.stopPropagation();
            this._focusElement(this.tcCheckBox);
        }
    };

    wrapOnShiftTab = (event: KeyboardEvent) => {
        if (event.key === 'Tab' && event.shiftKey) {
            event.preventDefault();
            event.stopPropagation();
            if (!this.tcDialogChecked) {
                this._focusElement(this.tcCancelBtn);
            } else {
                this._focusElement(this.tcConfirmBtn);
            }
        }
    };

    private _focusElement = (elementRef: ElementRef) => {
        this._renderer2.setAttribute(
            elementRef.nativeElement,
            'tabindex',
            '-1'
        );
        setTimeout(() => {
            elementRef.nativeElement.focus();
            this._renderer2.removeAttribute(
                elementRef.nativeElement,
                'tabindex'
            );
        }, 0);
    };
}
