/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shared-my-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../directives/shared-my-button.directive";
import * as i4 from "./shared-my-menu.component";
import * as i5 from "../../services/shared-menu.service";
var styles_SharedMyMenuComponent = [i0.styles];
var RenderType_SharedMyMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SharedMyMenuComponent, data: {} });
export { RenderType_SharedMyMenuComponent as RenderType_SharedMyMenuComponent };
function View_SharedMyMenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["aria-hidden", "true"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.icon; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SharedMyMenuComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "icon icon-new-window"]], null, null, null, null, null))], null, null); }
function View_SharedMyMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "button", [["role", "menuitem"], ["sharedMyButton", ""], ["tabindex", "-1"], ["type", "button"]], [[1, "aria-disabled", 0], [8, "disabled", 0]], [[null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (_co.resetMenuFocus($event, _v.context.index, true, _v.context.$implicit.command) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.toggleShowMenu($event, { command: _v.context.$implicit.command }) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, [[1, 4]], 0, i3.SharedMyButtonDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedMyMenuComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedMyMenuComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.icon; _ck(_v, 4, 0, currVal_2); var currVal_4 = _v.context.$implicit.showOpenInNewViewIcon; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.disabled; var currVal_1 = _v.context.$implicit.disabled; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.label; _ck(_v, 6, 0, currVal_3); }); }
function View_SharedMyMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["role", "menu"]], null, [[null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = (_co.handleBlur($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedMyMenuComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuOptions; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SharedMyMenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { myButtons: 1 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "menu-container"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedMyMenuComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showMenu; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_SharedMyMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-my-menu", [], null, null, null, View_SharedMyMenuComponent_0, RenderType_SharedMyMenuComponent)), i1.ɵdid(1, 245760, null, 0, i4.SharedMyMenuComponent, [i1.Renderer2, i1.ChangeDetectorRef, i5.SharedMenuService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SharedMyMenuComponentNgFactory = i1.ɵccf("shared-my-menu", i4.SharedMyMenuComponent, View_SharedMyMenuComponent_Host_0, { menuOptions: "menuOptions", toggleMenuButton: "toggleMenuButton" }, {}, ["*"]);
export { SharedMyMenuComponentNgFactory as SharedMyMenuComponentNgFactory };
