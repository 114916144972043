import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
    versionInfo;
    buildEnv = '';
    constructor() {}

    ngOnInit() {
        this.buildEnv = environment.envname;
        if (environment.version && environment.version.date) {
            this.versionInfo = environment.version;
        } else {
            this.versionInfo =
                'No Version Available Run Npm Build Or Version Script';
        }
    }
}
