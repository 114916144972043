import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IToastMessage, ToastType } from '@shared/models/IToastMessage';
import {
    animate,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';
import { Error } from 'tslint/lib/error';

@Component({
    selector: 'app-toast',
    templateUrl: './app-toast.component.html',
    styleUrls: ['./app-toast.component.scss'],
    animations: [
        trigger('openClose', [
            state(
                'open',
                style({
                    height: '80px',
                    top: '0',
                    opacity: 1,
                    marginBottom: '20px'
                })
            ),
            state(
                'closed',
                style({
                    opacity: 0,
                    height: '0px',
                    top: '-100px',
                    marginBottom: '0px'
                })
            ),
            transition('open => closed', [animate('0.5s ease-out')]),
            transition('closed => open', [animate('0.5s ease-in')])
        ])
    ]
})
export class AppToastComponent implements OnInit {
    isOpen: boolean = false;

    private _openDelay = 250;
    private _closeDuration = 500;

    private _timer;

    @Input() toastMsg: IToastMessage;
    @Output() onExpire = new EventEmitter<number>();

    constructor() {}

    ngOnInit() {
        this._timer = setTimeout(() => {
            this.expire();
        }, this.toastMsg.duration + this._openDelay);
        setTimeout(() => {
            this.isOpen = true;
        }, this._openDelay);
    }

    private expire = (): void => {
        if (this._timer) {
            this._timer = null;
        }
        this.isOpen = false;
        setTimeout(() => {
            // Wait 1 full second before removing toast ref from the toaster.
            this.onExpire.emit(this.toastMsg.id);
        }, this._closeDuration);
    };

    close = (): void => {
        this.expire();
    };

    hasIcon = (): boolean => {
        return !!this.toastMsg.iconRef;
    };

    iconRef = (): string => {
        switch (this.toastMsg.style) {
            case ToastType.NORMAL:
                return 'pi pi-check';
            case ToastType.ERROR:
            case ToastType.CAUTION:
                return 'icon icon-warning-pop-up';
            case ToastType.FATAL:
                return 'pi pi-times';
            default:
                throw new Error(
                    'Tried to fetch icon ref with invalid style on object'
                );
        }
    };

    iconStyle = (): string => {
        switch (this.toastMsg.style) {
            case ToastType.NORMAL:
                return 'normal-toast-icon';
            case ToastType.ERROR:
                return 'error-toast-icon';
            case ToastType.FATAL:
                return 'fatal-toast-icon';
            default:
                throw new Error(
                    'Tried to fetch icon style with invalid style on object'
                );
        }
    };

    toastStyle = (): string => {
        switch (this.toastMsg.style) {
            case ToastType.NORMAL:
                return 'normal-toast';
            case ToastType.ERROR:
                return 'error-toast';
            case ToastType.FATAL:
                return 'fatal-toast';
            default:
                throw new Error(
                    'Tried to fetch icon style with invalid style on object'
                );
        }
    };
}
