import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Institution } from '@shared/classes/institution';

@Injectable({
    providedIn: 'root'
})
export class SharedInstitutionDataStore {
    private _selectedInstitutionSubject: BehaviorSubject<
        Institution | undefined
    > = new BehaviorSubject<Institution | undefined>(undefined);
    public selectedInstitution$: Observable<
        Institution
    > = this._selectedInstitutionSubject.asObservable();

    private _resourcesUrlBS: BehaviorSubject<String> = new BehaviorSubject<
        String
    >(undefined);
    resourcesUrl$ = this._resourcesUrlBS.asObservable();
    constructor() {}

    setSelectedInstitution(institution: Institution) {
        // TODO: This is being called too many times. The logic flow between this
        // TODO: and the InstitutionSelectInstitutionComponent needs to be debugged.
        // keep resourceUrl in separate observable for convenience
        this._resourcesUrlBS.next(
            institution ? institution.resourcesUrl : undefined
        );
        this._selectedInstitutionSubject.next(institution);
    }
}
