import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResetPasswordComponent } from '@modules/auth/reset-password/reset-password.component';
import { CreatePasswordComponent } from '@modules/auth/create-password/create-password.component';
import { AuthLoginComponent } from '@modules/auth/components/auth-login/auth-login.component';
import { CreatePasswordGuard } from '@modules/auth/guards/create-password.guard';
import { AuthLoginGuard } from '@modules/auth/guards/auth-login.guard';

const routes: Routes = [
    {
        path: '',
        component: AuthLoginComponent,
        pathMatch: 'full',
        canActivate: [AuthLoginGuard]
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent
    },
    {
        path: 'confirm-reset',
        component: CreatePasswordComponent,
        canActivate: [CreatePasswordGuard]
    },
    {
        path: 'confirm-reset/:token',
        component: CreatePasswordComponent,
        canActivate: [CreatePasswordGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {}
