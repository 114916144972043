import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { SharedBaseMenuComponent } from '@shared/components/shared-base-menu/shared-base-menu.component';
import { Subject } from 'rxjs';
import { SharedMenuService } from '@shared/services/shared-menu.service';
import { takeUntil } from 'rxjs/operators';
var SharedMyMenuComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SharedMyMenuComponent, _super);
    function SharedMyMenuComponent(_renderer2, _changeDetectorRef, _sharedMenuService) {
        var _this = _super.call(this, _renderer2, _changeDetectorRef) || this;
        _this._renderer2 = _renderer2;
        _this._changeDetectorRef = _changeDetectorRef;
        _this._sharedMenuService = _sharedMenuService;
        _this.showMenu = false;
        _this._destroySubject = new Subject();
        _this.destroy$ = _this._destroySubject.asObservable();
        _this.toggleShowMenu = function (event, args) {
            if (args === void 0) { args = { command: null }; }
            event.preventDefault();
            event.stopPropagation();
            // Toggles the View Resources menu
            _this.showMenu = !_this.showMenu;
            _this._changeDetectorRef.detectChanges(); // Since we are accessing ViewChildren
            // which are contained inside of an ngIf, we must invoke changeDetection to
            // be able to detect the ViewChildren after the flag flips.
            if (!_this.showMenu) {
                // re-focuses on the View Resources
                // button if the menu has just closed.
                var viewResourcesButton_1 = _this.toggleMenuButton;
                _this._renderer2.setAttribute(viewResourcesButton_1, 'tabindex', '-1');
                if (viewResourcesButton_1) {
                    setTimeout(function () {
                        // This setTimeout has to be present for the view to re-render and the .focus call to work.
                        viewResourcesButton_1.focus();
                        _this._renderer2.removeAttribute(viewResourcesButton_1, 'tabindex');
                    }, 0);
                }
            }
            else {
                var buttons = _this.myButtons.toArray();
                var enabledIndexArray = _this.menuOptions
                    .map(function (option, index) {
                    return !option.disabled ? index : null;
                })
                    .filter(function (myIndex) {
                    return myIndex ? myIndex : myIndex === 0 ? true : false;
                });
                if (buttons[enabledIndexArray[0]]) {
                    buttons[enabledIndexArray[0]].elementRef.nativeElement.focus();
                }
            }
            if (args.command) {
                args.command();
            }
        };
        _this.handleBlur = function (event) {
            _this.toggleShowMenu(event);
        };
        _this._closeMenu = function () {
            // Toggles the View Resources menu
            _this.showMenu = false;
            _this._changeDetectorRef.detectChanges(); // Since we are accessing ViewChildren
            // which are contained inside of an ngIf, we must invoke changeDetection to
            // be able to detect the ViewChildren after the flag flips.
            // Re-focuses on the View Resources
            // button since the menu has just closed.
            var viewResourcesButton = _this.toggleMenuButton;
            _this._renderer2.setAttribute(viewResourcesButton, 'tabindex', '-1');
            if (viewResourcesButton) {
                setTimeout(function () {
                    // This setTimeout has to be present for the view to re-render and the .focus call to work.
                    viewResourcesButton.focus();
                    _this._renderer2.removeAttribute(viewResourcesButton, 'tabindex');
                }, 0);
            }
        };
        return _this;
    }
    SharedMyMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._sharedMenuService.closeAllMenus$
            .pipe(takeUntil(this.destroy$))
            .subscribe(function () {
            if (_this.showMenu) {
                // We only call _closeMenu if the menu
                // is open. Otherwise, there is a race condition between all
                // Menus on the page where the last one's target button will
                // be focused, rather than the target of the menu that was open.
                _this._closeMenu();
            }
        });
    };
    SharedMyMenuComponent.prototype.ngOnDestroy = function () {
        this._destroySubject.next();
        this._destroySubject.complete();
    };
    return SharedMyMenuComponent;
}(SharedBaseMenuComponent));
export { SharedMyMenuComponent };
