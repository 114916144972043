/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-error-msg.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./app-error-msg.component";
import * as i4 from "../../services/shared-validation.service";
var styles_AppErrorMsgComponent = [i0.styles];
var RenderType_AppErrorMsgComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppErrorMsgComponent, data: {} });
export { RenderType_AppErrorMsgComponent as RenderType_AppErrorMsgComponent };
function View_AppErrorMsgComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-exclamation-triangle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "message-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 3, 0, currVal_0); }); }
export function View_AppErrorMsgComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "my-error"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppErrorMsgComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "my-error"; var currVal_1 = _co.styleClass; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.show; _ck(_v, 3, 0, currVal_2); }, null); }
export function View_AppErrorMsgComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error-msg", [], null, null, null, View_AppErrorMsgComponent_0, RenderType_AppErrorMsgComponent)), i1.ɵdid(1, 638976, null, 0, i3.AppErrorMsgComponent, [i4.SharedValidationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppErrorMsgComponentNgFactory = i1.ɵccf("app-error-msg", i3.AppErrorMsgComponent, View_AppErrorMsgComponent_Host_0, { control: "control", show: "show" }, {}, []);
export { AppErrorMsgComponentNgFactory as AppErrorMsgComponentNgFactory };
