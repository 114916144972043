import { BehaviorSubject } from 'rxjs';
import { ToastType } from '@shared/models/IToastMessage';
import * as i0 from "@angular/core";
var ToastService = /** @class */ (function () {
    function ToastService() {
        var _this = this;
        this._toastQueueSubject = new BehaviorSubject(undefined);
        this._toastQueueObservable$ = this._toastQueueSubject.asObservable();
        this._runningToastIndex = 0;
        this._defaultToastDuration = 8000; // in ms
        this._defaultToastIcon = 'check';
        this.queueToast = function (toastMsg) {
            if (toastMsg) {
                toastMsg.id = _this._runningToastIndex;
                _this._toastQueueSubject.next(toastMsg);
                _this._runningToastIndex++; // increment index so next toast uses new id
            }
            else {
                console.error("Tried to submit an undefined toast object to the toast queue");
            }
        };
        this.queueDefaultToastWithMessage = function (msg) {
            if (msg) {
                var toast = {
                    duration: _this._defaultToastDuration,
                    iconRef: _this._defaultToastIcon,
                    messageHtml: msg,
                    style: ToastType.NORMAL
                };
                _this.queueToast(toast);
            }
            else {
                console.error("Tried to submit a default toast with no message to the toast queue");
            }
        };
        this.queueErrorToastWithMessage = function (msg) {
            if (msg === void 0) { msg = "Action was unsuccessful, please try again."; }
            if (msg) {
                var toast = {
                    duration: _this._defaultToastDuration,
                    iconRef: _this._defaultToastIcon,
                    messageHtml: msg,
                    style: ToastType.ERROR
                };
                _this.queueToast(toast);
            }
            else {
                console.error("Tried to submit an error toast with no message to the toast queue");
            }
        };
        this.queueFatalToastWithMessage = function (msg) {
            if (msg) {
                var toast = {
                    duration: _this._defaultToastDuration,
                    iconRef: _this._defaultToastIcon,
                    messageHtml: msg,
                    style: ToastType.FATAL
                };
                _this.queueToast(toast);
            }
            else {
                console.error("Tried to submit an error toast with no message to the toast queue");
            }
        };
    }
    ToastService.prototype.purgeToast = function () {
        this._toastQueueSubject.next({
            style: ToastType.BLANK,
            duration: 0.1,
            id: -1,
            iconRef: '',
            messageHtml: ''
        });
    };
    Object.defineProperty(ToastService.prototype, "toastQueue$", {
        get: function () {
            return this._toastQueueObservable$;
        },
        enumerable: true,
        configurable: true
    });
    ToastService.ngInjectableDef = i0.defineInjectable({ factory: function ToastService_Factory() { return new ToastService(); }, token: ToastService, providedIn: "root" });
    return ToastService;
}());
export { ToastService };
