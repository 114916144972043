import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { User } from '../../classes/user';
import { Router } from '@angular/router';
import { AuthService } from '@modules/auth/services/auth.service';
import { AuthUserService } from '@modules/auth/services/auth-user.service';
import { Institution } from '@shared/classes/institution';
import { SharedInstitutionDataStore } from '@shared/services/shared-institution-data-store.service';
import { SidenavService } from '@shared/services/sidenav.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { InstitutionService } from '@modules/institution/services/institution.service';
var SharedLeftMenuPresentationComponent = /** @class */ (function () {
    function SharedLeftMenuPresentationComponent(router, _authUserService, _sharedInstitutionDataStore, _sidenavService, _authService, _institutionService) {
        var _this = this;
        this.router = router;
        this._authUserService = _authUserService;
        this._sharedInstitutionDataStore = _sharedInstitutionDataStore;
        this._sidenavService = _sidenavService;
        this._authService = _authService;
        this._institutionService = _institutionService;
        this.hide = new EventEmitter();
        this.currentYear = new Date().getFullYear();
        this.isUserAdminOrSuperAdmin = false;
        this._destroySubject = new Subject();
        this._destroy$ = this._destroySubject.asObservable();
        this.handleFocus = function (event) {
            _this._sidenavService.showAside$
                .pipe(take(1), takeUntil(_this._destroy$))
                .subscribe(function (flag) {
                if (!flag) {
                    _this._sidenavService.focusExpandSidenavButton();
                }
            });
        };
    }
    Object.defineProperty(SharedLeftMenuPresentationComponent.prototype, "user", {
        get: function () {
            return this._user;
        },
        // Otherwise there is no selectedInstitution when this._checkUserRole is called.
        set: function (user) {
            this._user = user;
            this.isUserAdminOrSuperAdmin = this._checkUserRole();
        },
        enumerable: true,
        configurable: true
    });
    SharedLeftMenuPresentationComponent.prototype.ngOnInit = function () {
        this.hasAccessToSingleInstitution = this._institutionService.userOnlyHasOneInstitution;
    };
    SharedLeftMenuPresentationComponent.prototype.ngOnDestroy = function () {
        this._destroySubject.next();
        this._destroySubject.complete();
    };
    SharedLeftMenuPresentationComponent.prototype.onLogout = function () {
        this._authService.logout();
    };
    SharedLeftMenuPresentationComponent.prototype.selectedInstitutionName = function () {
        return this.selectedInstitution ? this.selectedInstitution.name : null;
    };
    SharedLeftMenuPresentationComponent.prototype.selectedInstitutionCity = function () {
        return this.selectedInstitution.address
            ? this.selectedInstitution.address.city
            : null;
    };
    SharedLeftMenuPresentationComponent.prototype.selectedInstitutionState = function () {
        return this.selectedInstitution.address
            ? this.selectedInstitution.address.state.name
                ? this.selectedInstitution.address.state.name
                : null
            : null;
    };
    SharedLeftMenuPresentationComponent.prototype.hasLocationInfo = function () {
        return (!!this.selectedInstitution.address &&
            this.selectedInstitution.address.city &&
            this.selectedInstitution.address.state &&
            this.selectedInstitution.address.city !== '' &&
            this.selectedInstitution.address.state.name !== '');
    };
    SharedLeftMenuPresentationComponent.prototype._checkUserRole = function () {
        var result = false;
        if (this.selectedInstitution &&
            this.selectedInstitution.userRole === 'ROLE_ADMIN') {
            result = true;
        }
        else if (this.user && this.user.superUser) {
            result = true;
        }
        return result;
    };
    return SharedLeftMenuPresentationComponent;
}());
export { SharedLeftMenuPresentationComponent };
