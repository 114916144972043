import { Component, OnInit } from '@angular/core';
import { ToastService } from '@shared/services/toast.service';

@Component({
    selector: 'auth-no-auth-shell',
    templateUrl: './no-auth-shell.component.html',
    styleUrls: ['./no-auth-shell.component.scss']
})
export class NoAuthShellComponent implements OnInit {
    constructor(private _toastService: ToastService) {}

    ngOnInit() {}
}
