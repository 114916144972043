import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedValidationService } from '@shared/services/shared-validation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { PasswordValidator } from '@shared/classes/password-validator';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '@modules/auth/services/auth.service';
import { Observable, Subject } from 'rxjs';
import { ToastService } from '@shared/services/toast.service';
import { debounceTime, finalize, take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'auth-create-password',
    templateUrl: './create-password.component.html',
    styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit, OnDestroy {
    passwordForm: FormGroup;
    passwordValid = false;
    passwordValidator = new PasswordValidator();
    validationsPassed: boolean = false;
    submitting: boolean = false;

    private _token: string;
    private _destroySubject: Subject<void> = new Subject<void>();
    private _destroy$: Observable<void> = this._destroySubject.asObservable();

    constructor(
        private router: Router,
        private _route: ActivatedRoute,
        private _location: Location,
        private _toastService: ToastService,
        private authService: AuthService,
        private validationService: SharedValidationService,
        private formBuilder: FormBuilder // private userService: UserService
    ) {}

    ngOnInit() {
        this.passwordForm = this.formBuilder.group(
            {
                password: ['', [Validators.required]],
                confirmpassword: [
                    { value: '', disabled: true },
                    [Validators.required]
                ]
            },
            {
                validator: this.validationService.matchingPasswords(
                    'password',
                    'confirmpassword'
                )
            }
        );

        this.authService.resetPasswordToken$
            .pipe(
                take(1),
                takeUntil(this._destroy$) // Always use takeUntil when using take. Prevents memory leaks.
            )
            .subscribe(token => {
                this._token = token;
            });
        this.passwordForm.controls.password.valueChanges
            .pipe(
                debounceTime(400),
                takeUntil(this._destroy$)
            )
            .subscribe(value => this.passwordRequirementsValidator(value));
    }

    ngOnDestroy(): void {
        this._destroySubject.next();
        this._destroySubject.complete();
    }

    passwordRequirementsValidator(value: string) {
        if (!value) {
            this.passwordValidator.reset();
            this.validationsPassed = false;
            this.passwordForm.controls.confirmpassword.setValue('');
            this.passwordForm.controls.confirmpassword.disable();
        } else {
            this.passwordForm.controls.confirmpassword.enable();
            // This fires a call to an API to validate the password server-side,
            // then sets the validity of Front-End controls accordingly based on
            // the response. This is the common pattern used across all apps.
            this.authService.validatePassword(value).subscribe(
                passwordValidations => {
                    passwordValidations.noSpaces = !value.match(/\s/gi);
                    this.passwordValidator.setAllValidations(
                        passwordValidations
                    );
                    this.validationsPassed = this.passwordValidator.checkAllValidations();
                    this.passwordValidator.checkAllValidations()
                        ? this.passwordForm.controls.confirmpassword.enable()
                        : this.passwordForm.controls.confirmpassword.disable();
                },
                error => {
                    if (error.status === 401) {
                        this.router.navigate(['/login']);
                    }
                }
            );
        }
    }

    submitReset() {
        const password = this.passwordForm.controls.password.value;

        this.authService.validatePassword(password).subscribe(
            res => {
                this.executeReset(password);
            },
            err => {
                this._toastService.queueErrorToastWithMessage(
                    'Password invalid'
                );
            }
        );
    }

    executeReset(password: string) {
        this.submitting = true;
        const myUrl = window.location.href + '/' + this._token;
        this.authService
            .resetPassword(password, myUrl)
            // Note: using window.location.href here tightly couples our app
            // to the browser. If server-side rendering is implemented, this may
            // need to be refactored.
            .pipe(
                finalize(() => {
                    this.submitting = false;
                })
            )
            .subscribe(
                res => {
                    this._toastService.queueDefaultToastWithMessage(
                        'Password successfully changed'
                    );
                    this.router.navigate(['/login']);
                },
                err => {
                    console.error('Reset failure: ' + JSON.stringify(err));
                }
            );
    }
}
