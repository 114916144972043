import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from '@modules/auth/interceptors/auth-interceptor.interceptor';
import 'shim-keyboard-event-key';
// For less verbose relative path imports, the following are configured in tsconfig.app.json and can be used throughout the project:
// @app
// @modules
// @environment
// @shared
// @styles
// Note that tsconfig.app.json simply extends tsconfig.json.

import { AuthModule } from '@modules/auth/auth.module';
import { SharedModule } from '@shared/shared.module';
import { ShellComponent } from '@app/shell/shell.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { SharedAsideContentComponent } from '@shared/presentation-components/shared-aside-content/shared-aside-content.component';
import { SharedMainContentComponent } from '@shared/presentation-components/shared-main-content/shared-main-content.component';
import { VersionComponent } from './version/version.component';

@NgModule({
    declarations: [
        AppComponent,
        ShellComponent,
        SharedAsideContentComponent,
        SharedMainContentComponent,
        VersionComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        AuthModule,
        SharedModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        MessageService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
