import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var SharedInstitutionDataStore = /** @class */ (function () {
    function SharedInstitutionDataStore() {
        this._selectedInstitutionSubject = new BehaviorSubject(undefined);
        this.selectedInstitution$ = this._selectedInstitutionSubject.asObservable();
        this._resourcesUrlBS = new BehaviorSubject(undefined);
        this.resourcesUrl$ = this._resourcesUrlBS.asObservable();
    }
    SharedInstitutionDataStore.prototype.setSelectedInstitution = function (institution) {
        // TODO: This is being called too many times. The logic flow between this
        // TODO: and the InstitutionSelectInstitutionComponent needs to be debugged.
        // keep resourceUrl in separate observable for convenience
        this._resourcesUrlBS.next(institution ? institution.resourcesUrl : undefined);
        this._selectedInstitutionSubject.next(institution);
    };
    SharedInstitutionDataStore.ngInjectableDef = i0.defineInjectable({ factory: function SharedInstitutionDataStore_Factory() { return new SharedInstitutionDataStore(); }, token: SharedInstitutionDataStore, providedIn: "root" });
    return SharedInstitutionDataStore;
}());
export { SharedInstitutionDataStore };
