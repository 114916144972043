import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { User } from '@shared/classes/user';
import { SharedConstantsService } from '@shared/services/shared-constants.service';
import { map, catchError, switchMap } from 'rxjs/operators';
import { AuthUserService } from './auth-user.service';
import { Router } from '@angular/router';
import { SharedBaseService } from '@shared/services/shared-base.service';
import { SharedMyMessageService } from '@shared/services/shared-my-message.service';
import { environment } from '@environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/services/shared-constants.service";
import * as i2 from "./auth-user.service";
import * as i3 from "@angular/common/http";
import * as i4 from "../../../shared/services/shared-my-message.service";
import * as i5 from "@angular/router";
var AuthService = /** @class */ (function (_super) {
    tslib_1.__extends(AuthService, _super);
    function AuthService(_Constants, _authUserService, http, __sharedMyMessageService, _router) {
        var _this = _super.call(this, http, __sharedMyMessageService) || this;
        _this._Constants = _Constants;
        _this._authUserService = _authUserService;
        _this.__sharedMyMessageService = __sharedMyMessageService;
        _this._router = _router;
        _this.tokenKey = 'userToken';
        _this._resetPasswordTokenBehaviorSubject = new BehaviorSubject(undefined);
        _this.resetPasswordToken$ = _this._resetPasswordTokenBehaviorSubject.asObservable();
        _this._clearUserDataSubject = new Subject();
        _this.clearUserData$ = _this._clearUserDataSubject.asObservable();
        _this.isAuthorized = function () {
            var token = _this.getToken();
            return !!token;
        };
        _this.saveToken = function (aToken) {
            localStorage.setItem(_this.tokenKey, aToken);
        };
        _this.clearToken = function () {
            localStorage.removeItem(_this.tokenKey);
        };
        _this.getToken = function () {
            var token = localStorage.getItem(_this.tokenKey);
            return token;
        };
        _this.hasAcceptedTC = function () {
            var userStr = localStorage.getItem('user');
            var user = new User(JSON.parse(userStr));
            return user.currentTermsAndConditions;
        };
        _this.tcPath = function () {
            var userStr = localStorage.getItem('user');
            var user = new User(JSON.parse(userStr));
            return user.currentTermsAndConditionsPath;
        };
        _this.loginByToken = function (token, customerId) {
            return _this.http
                .get(_this.baseUrl + "/loginByToken/" + token + "/" + customerId)
                .pipe(switchMap(function (res) {
                var associationIds = [];
                if (!res.user.associations) {
                    // logout in case of no user associations. api is not throwing 401 error for some reason in case of expired token
                    _this.logoutOnClientOnly();
                }
                for (var i = 0; i < res.user.associations.length; i++) {
                    associationIds.push(res.user.associations[i].id);
                }
                var loggedInUser = new User({
                    id: res.user.id,
                    email: res.user.email,
                    firstName: res.user.firstName,
                    middleName: null,
                    lastName: res.user.lastName,
                    token: res.token,
                    superUser: res.user.superUser,
                    associations: res.user.associations,
                    associationIds: associationIds,
                    currentTermsAndConditions: res.user.currentTermsAndConditions,
                    currentTermsAndConditionsPath: res.user.currentTermsAndConditionsPath,
                    ssoUserId: res.user.ssoUserId
                });
                _this.saveToken(loggedInUser.token);
                _this._authUserService.setUser(loggedInUser);
                return of({
                    token: loggedInUser.token,
                    user: loggedInUser
                });
            }), catchError(_this.handleError));
        };
        _this.saveResetToken = function (rToken) {
            _this._resetPasswordTokenBehaviorSubject.next(rToken);
        };
        return _this;
    }
    AuthService.prototype.acceptTerms = function () {
        var token = localStorage.getItem('userToken');
        return this.http.post("" + this.baseUrl + SharedConstantsService.ENDPOINTS.TERMS_AND_CONDITIONS, {}, {
            headers: {
                Authorization: token
            }
        });
    };
    /**
     * Will attempt login for user's given credentials and return a user object with associated token
     * Token and user object will both be saved to local storage
     * @param {Credentials} credentials
     * @returns {Observable<{token: string; user: User}>}
     */
    AuthService.prototype.login = function (credentials) {
        var _this = this;
        return this.http
            .post("" + this.baseUrl + SharedConstantsService.ENDPOINTS.LOGIN, credentials)
            .pipe(map(function (res) {
            var associationIds = [];
            for (var i = 0; i < res.user.associations.length; i++) {
                associationIds.push(res.user.associations[i].id);
            }
            var loggedInUser = new User({
                id: res.user.id,
                email: res.user.email,
                firstName: res.user.firstName,
                middleName: null,
                lastName: res.user.lastName,
                token: res.token,
                superUser: res.user.superUser,
                associations: res.user.associations,
                associationIds: associationIds,
                currentTermsAndConditions: res.user.currentTermsAndConditions,
                currentTermsAndConditionsPath: res.user.currentTermsAndConditionsPath,
                ssoUserId: res.user.ssoUserId
            });
            _this.saveToken(loggedInUser.token);
            _this._authUserService.setUser(loggedInUser);
            return {
                token: loggedInUser.token,
                user: loggedInUser
            };
        }));
    };
    AuthService.prototype.validateForgotPasswordLink = function (link) {
        var payload = { url: link };
        return this.http.post("" + this.baseUrl + SharedConstantsService.ENDPOINTS.FORGOT_PASSWORD_VALIDATE, payload);
    };
    AuthService.prototype.logout = function () {
        this.logoutOnClientOnly();
    };
    AuthService.prototype.clearUserSession = function () {
        this.clearToken();
        this._authUserService.clearUser();
        this._clearUserDataSubject.next();
    };
    AuthService.prototype.logoutOnClientOnly = function () {
        this.clearToken();
        this._authUserService.clearUser();
        this._clearUserDataSubject.next();
        window.open(environment.myJourneyUrl + 'logout', '_self');
    };
    AuthService.prototype.forgotPassword = function (email) {
        return this.http
            .post("" + this.baseUrl + SharedConstantsService.ENDPOINTS.FORGOT_PASSWORD, { email: email })
            .pipe(map(function (res) {
            return res;
        }));
    };
    AuthService.prototype.resetPassword = function (newPass, resetUrl) {
        var payload = { url: resetUrl, password: newPass };
        return this.http.put("" + this.baseUrl + SharedConstantsService.ENDPOINTS.RESET_PASSWORD, payload, { responseType: 'text' });
    };
    AuthService.prototype.validatePassword = function (newPass) {
        var payload = {
            password: newPass
        };
        return this.http.post("" + this.baseUrl + SharedConstantsService.ENDPOINTS.VALIDATE_PASSWORD, payload);
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.SharedConstantsService), i0.inject(i2.AuthUserService), i0.inject(i3.HttpClient), i0.inject(i4.SharedMyMessageService), i0.inject(i5.Router)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}(SharedBaseService));
export { AuthService };
