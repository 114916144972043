import { environment } from '@environments/environment';
import * as i0 from "@angular/core";
var AuthLoginGuard = /** @class */ (function () {
    function AuthLoginGuard() {
    }
    AuthLoginGuard.prototype.canActivate = function (next, state) {
        window.open(environment.myJourneyUrl + 'login', '_self');
        return false;
    };
    AuthLoginGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthLoginGuard_Factory() { return new AuthLoginGuard(); }, token: AuthLoginGuard, providedIn: "root" });
    return AuthLoginGuard;
}());
export { AuthLoginGuard };
