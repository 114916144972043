import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { SharedHeaderPresentationComponent } from './presentation-components/shared-header-presentation/shared-header-presentation.component';
import { SharedHeaderSmartComponent } from './smart-components/shared-header-smart/shared-header-smart.component';
import { ButtonModule } from 'primeng/button';
import {
    BreadcrumbModule,
    TooltipModule,
    MenuModule,
    SidebarModule,
    AccordionModule,
    RadioButtonModule,
    CheckboxModule,
    AutoCompleteModule,
    ListboxModule,
    DialogModule,
    TreeModule,
    ProgressSpinnerModule
} from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { StrongPipe } from '@shared/pipes/strong.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { SharedNoauthHeaderPresentationComponent } from '@shared/presentation-components/shared-noauth-header-presentation/shared-noauth-header-presentation.component';
import { SharedNoauthHeaderSmartComponent } from '@shared/smart-components/shared-noauth-header-smart/shared-noauth-header-smart.component';
import { AppErrorMsgComponent } from './components/app-error-msg/app-error-msg.component';
import { AppToastComponent } from '@shared/components/app-toast/app-toast.component';
import { ToasterComponent } from '@shared/components/toaster/toaster.component';
import { SharedLeftMenuSmartComponent } from './smart-components/shared-left-menu-smart/shared-left-menu-smart.component';
import { SharedLeftMenuPresentationComponent } from '@shared/presentation-components/shared-left-menu-presentation/shared-left-menu-presentation.component';
import { SharedMyMenuComponent } from './components/shared-my-menu/shared-my-menu.component';
import { SharedMyButtonDirective } from './directives/shared-my-button.directive';
import { SharedMyPopoverMenuComponent } from './components/shared-my-popover-menu/shared-my-popover-menu.component';
import { SharedTrimEmailDirective } from './directives/shared-trim-email.directive';
import { SharedMyCdkPopoverComponent } from './components/shared-my-cdk-popover/shared-my-cdk-popover.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SharedInMainContainerOverlayContainer } from '@shared/services/shared-in-main-container-overlay-container';
import { A11yModule } from '@angular/cdk/a11y';
import { SharedSortFilterMenuComponent } from './components/shared-sort-filter-menu/shared-sort-filter-menu.component';

@NgModule({
    declarations: [
        SharedHeaderPresentationComponent,
        SharedHeaderSmartComponent,
        SharedNoauthHeaderPresentationComponent,
        SharedNoauthHeaderSmartComponent,
        StrongPipe,
        AppErrorMsgComponent,
        AppToastComponent,
        ToasterComponent,
        SharedLeftMenuSmartComponent,
        SharedLeftMenuPresentationComponent,
        SharedMyMenuComponent,
        SharedMyButtonDirective,
        SharedMyPopoverMenuComponent,
        SharedTrimEmailDirective,
        SharedMyCdkPopoverComponent,
        SharedSortFilterMenuComponent
    ],
    exports: [
        SharedHeaderPresentationComponent,
        SharedHeaderSmartComponent,
        SharedNoauthHeaderPresentationComponent,
        SharedNoauthHeaderSmartComponent,
        AppToastComponent,
        ToasterComponent,
        AppErrorMsgComponent,
        ButtonModule,
        BreadcrumbModule,
        TableModule,
        TooltipModule,
        StrongPipe,
        SidebarModule,
        AccordionModule,
        RadioButtonModule,
        CheckboxModule,
        AutoCompleteModule,
        ListboxModule,
        DialogModule,
        TreeModule,
        ProgressSpinnerModule,
        ToastModule,
        FormsModule,
        ReactiveFormsModule,
        OverlayModule,
        ScrollingModule,
        SharedLeftMenuSmartComponent,
        SharedLeftMenuPresentationComponent,
        SharedMyMenuComponent,
        SharedSortFilterMenuComponent,
        SharedMyButtonDirective,
        SharedMyPopoverMenuComponent,
        SharedTrimEmailDirective,
        A11yModule
    ],
    entryComponents: [SharedMyCdkPopoverComponent],
    imports: [
        CommonModule,
        ButtonModule,
        BreadcrumbModule,
        TableModule,
        TooltipModule,
        MenuModule,
        SidebarModule,
        AccordionModule,
        RadioButtonModule,
        CheckboxModule,
        AutoCompleteModule,
        ListboxModule,
        DialogModule,
        TreeModule,
        ProgressSpinnerModule,
        ToastModule,
        FormsModule,
        ReactiveFormsModule,
        OverlayModule,
        ScrollingModule,
        A11yModule
    ],
    providers: [
        {
            provide: OverlayContainer,
            useClass: SharedInMainContainerOverlayContainer
        }
    ]
})
export class SharedModule {}
