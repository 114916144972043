import { Router, ActivatedRoute } from '@angular/router';
import { throwError } from 'rxjs';
import { AuthService } from '@modules/auth/services/auth.service';
import { catchError, take, map } from 'rxjs/operators';
import { Location } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "../modules/auth/services/auth.service";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
var AssessmentsAuthGuard = /** @class */ (function () {
    function AssessmentsAuthGuard(_authService, _router, _route, _location) {
        var _this = this;
        this._authService = _authService;
        this._router = _router;
        this._route = _route;
        this._location = _location;
        this._authenticateUser = function (next) {
            // If the auth token is passed in URL, check if there's already one in our local storage.
            // If token in our local is different then the one passed in URL, clear the old one, so that the new user can be logged in
            if (next.queryParamMap.get('authToken') &&
                _this._authService.getToken() &&
                next.queryParamMap.get('authToken') !== _this._authService.getToken()) {
                // clear current session info, a new user is trying to log in
                _this._authService.clearUserSession();
            }
            if (_this._authService.isAuthorized()) {
                // If there is an auth token in the query params of the route
                // we must strip it out.
                if (next.queryParamMap.get('authToken')) {
                    _this._stripAuthTokenResetHistoryAndNavigate();
                    return false; // We block navigation to original route with
                    // AuthToken in params, and instead nav to new route with
                    // AuthToken stripped out.
                }
                // Else proceed.
                return true;
            }
            else if (!_this._authService.isAuthorized() &&
                next.queryParamMap.get('authToken')) {
                var token = next.queryParamMap.get('authToken');
                var customerId = next.firstChild.paramMap.get('institutionId');
                return _this._authService.loginByToken(token, customerId).pipe(map(function (res) {
                    _this._stripAuthTokenResetHistoryAndNavigate();
                    return false; // We block navigation to original route with
                    // AuthToken in params, and instead nav to new route with
                    // AuthToken stripped out.
                }), take(1), catchError(function (err) {
                    return throwError(err);
                }));
            }
            else if (!_this._authService.isAuthorized() &&
                !next.queryParamMap.get('authToken')) {
                _this._authService.logoutOnClientOnly();
                return false;
            }
            else {
                _this._authService.logoutOnClientOnly();
                return false;
            }
        };
        this._stripAuthTokenResetHistoryAndNavigate = function () {
            var replacementPath = _this._location
                .path()
                .replace(/(([?|&]authToken=[^&]+))/gi, '');
            _this._router.navigate([replacementPath]);
            _this._location.replaceState(replacementPath); // Strip token from path and replace URL history.
            return replacementPath;
        };
    }
    AssessmentsAuthGuard.prototype.canActivate = function (next, state) {
        return this._authenticateUser(next);
    };
    AssessmentsAuthGuard.prototype.canActivateChild = function (next, state) {
        return this._authenticateUser(next);
    };
    AssessmentsAuthGuard.prototype.canLoad = function (route, segments) {
        if (this._authService.isAuthorized()) {
            return true;
        }
        else {
            this._router.navigate(['login']);
            return false;
        }
    };
    AssessmentsAuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AssessmentsAuthGuard_Factory() { return new AssessmentsAuthGuard(i0.inject(i1.AuthService), i0.inject(i2.Router), i0.inject(i2.ActivatedRoute), i0.inject(i3.Location)); }, token: AssessmentsAuthGuard, providedIn: "root" });
    return AssessmentsAuthGuard;
}());
export { AssessmentsAuthGuard };
