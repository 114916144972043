import { HttpClient } from '@angular/common/http';
import { ObservableInput, throwError } from 'rxjs';
import { environment } from '@environments/environment';
import { SharedMyMessageService } from '@shared/services/shared-my-message.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./shared-my-message.service";
var SharedBaseService = /** @class */ (function () {
    // protected mockUrl: string = environment.mockUrl;
    function SharedBaseService(http, _sharedMyMessageService) {
        var _this = this;
        this.http = http;
        this._sharedMyMessageService = _sharedMyMessageService;
        this.baseUrl = environment.baseUrl;
        this.customerApiUrl = environment.customerUrl;
        this.handleError = function (err) {
            _this._sharedMyMessageService.showError({ detail: err.message });
            return throwError(err);
        };
    }
    SharedBaseService.ngInjectableDef = i0.defineInjectable({ factory: function SharedBaseService_Factory() { return new SharedBaseService(i0.inject(i1.HttpClient), i0.inject(i2.SharedMyMessageService)); }, token: SharedBaseService, providedIn: "root" });
    return SharedBaseService;
}());
export { SharedBaseService };
